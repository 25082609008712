import { authAction } from "../actions/authAction";
const { adminLogin } = authAction;

const dispatchAdminLogin = (dispatch) => (credential) => {
  dispatch(adminLogin(credential));
};

const authOperations = {
  dispatchAdminLogin,
};

export default authOperations;
