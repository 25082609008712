import { combineReducers } from "redux";
import authReducer from "./authReducer";
import adminReducer from "./adminReducer";
import helpReducer from "./helpReducer";
import homeReducer from "./homeReducer";
import journalReducer from "./journalReducer";

const rootReducer = combineReducers({
  home: homeReducer,
  auth: authReducer,
  admin: adminReducer,
  "raise-query": helpReducer,
  journal: journalReducer,
});

export default rootReducer;
