import { homeAction } from "../actions/homeAction";
const { getHomeData, updateEditors, getEditors } = homeAction;

const dispatchHomeData = (dispatch) => () => {
  dispatch(getHomeData());
};

const dispatchGetEditors = (dispatch) => () => {
  dispatch(getEditors());
};

const dispatchUpdateEditors = (dispatch) => (editorsData) => {
  dispatch(updateEditors(editorsData));
};

const homeOperations = {
  dispatchHomeData,
  dispatchGetEditors,
  dispatchUpdateEditors,
};

export default homeOperations;
