/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Space, Table, Tag, Modal, Button, Form, Input, Select } from "antd";
import { useSelector } from "react-redux";
import adminSelectors from "../../redux/selectors/adminSelectors";
import adminOperation from "../../redux/operations/adminOperations";
import "./UserTableComponent.css";
import SessionHandler from "../../utils/SessionHandler";

const UserTableComponent = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const persona = SessionHandler.getSession().persona;
  const [userList, setUserList] = useState([]);
  const [changedUserStatus, setChangedUserStatus] = useState(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [newUserAdding, setNewUserAdding] = useState(false);
  const { isFetchingUsers, listOfUsers, isUpdatingUsers } = useSelector(
    (state) => ({
      isFetchingUsers: adminSelectors.isFetchingUsers(state),
      listOfUsers: adminSelectors.users(state),
      isUpdatingUsers: adminSelectors.isUpdatingUsers(state),
    })
  );
  const [data, setData] = useState([]);
  useEffect(() => {
    let tempUsers = userList;
    if (tempUsers !== undefined) {
      for (let i = 0; i < tempUsers.length; i++) {
        let item = tempUsers[i];
        item.key = item._id;
        tempUsers[i] = item;
      }
      setData(tempUsers);
    }
  }, [userList]);

  useEffect(() => {
    setUserList(listOfUsers);
  }, [listOfUsers]);

  useEffect(() => {
    if (!isUpdatingUsers) {
      setChangedUserStatus(false);
    }
  }, [isUpdatingUsers]);

  const isEmailFieldEmpty = (form) => {
    const emailFieldValue = form.getFieldValue("email");
    return !emailFieldValue || emailFieldValue.trim() === "";
  };

  const showEditUserModal = (record) => {
    setNewUserAdding(false);
    if ("password" in record) {
      delete record["password"];
    }
    form.setFieldsValue(record);
    setIsEditUserModalOpen(true);
  };
  const handleEditUserModalOk = () => {
    setIsEditUserModalOpen(false);
  };
  const handleEditUserModalCancel = () => {
    setIsEditUserModalOpen(false);
  };
  const updateUser = (values) => {
    const userData = data;
    let emailExists = false;

    let updatedUsers = userData.map((singleUser) => {
      if (singleUser.email === values.email) {
        emailExists = true;
        let updatingUser = {
          ...singleUser,
          name: values.name,
          persona: values.persona,
          password: btoa(values.password),
          locked: values.locked,
          record_status: "update",
        };
        if (
          updatingUser.password === "" ||
          updatingUser.password === undefined
        ) {
          delete updatingUser.password;
        }
        return updatingUser;
      }
      return singleUser;
    });

    if (!emailExists) {
      let newUser = {
        key: values.email,
        email: values.email,
        name: values.name,
        persona: values.persona,
        locked: values.locked,
        password: btoa(values.password),
        record_status: "append",
      };
      if (newUser.password === "" || newUser.password === undefined) {
        delete newUser.password;
      }
      updatedUsers.push(newUser);
    }

    setChangedUserStatus(true);
    setData(updatedUsers);
  };
  const undoChanges = () => {
    setChangedUserStatus(false);
    setData(userList);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      editable: true,
      render: (text, record) => {
        let color = "black";
        if (record.record_status === "delete") {
          color = "red";
        } else if (record.record_status === "update") {
          color = "blue";
        } else if (record.record_status === "append") {
          color = "green";
        }
        return <span style={{ color: color }}>{text}</span>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      editable: true,
      render: (text, record) => {
        let color = "black";
        if (record.record_status === "delete") {
          color = "red";
        } else if (record.record_status === "update") {
          color = "blue";
        } else if (record.record_status === "append") {
          color = "green";
        }
        return <span style={{ color: color }}>{text}</span>;
      },
    },
    {
      title: "Persona",
      dataIndex: "persona",
      editable: true,
      render: (text, record) => {
        let color = "black";
        if (record.record_status === "delete") {
          color = "red";
        } else if (record.record_status === "update") {
          color = "blue";
        } else if (record.record_status === "append") {
          color = "green";
        }
        return <span style={{ color: color }}>{text}</span>;
      },
    },
    {
      title: "Locked",
      dataIndex: "locked",
      render: (text, record) => {
        const color = record.locked === "Locked" ? "red" : "green";
        const lock = record.locked;
        return <Tag color={color}>{lock}</Tag>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <Space size="middle">
            <Button
              type="link"
              onClick={() => showEditUserModal(record)}
              disabled={persona !== "Admin"}
            >
              Edit {record.name}
            </Button>
          </Space>
        );
      },
    },
  ];

  const deleteUser = (record) => {
    const userData = data;
    const deleteUsers = userData.map((singleUser) => {
      if (singleUser.email === record.email) {
        return {
          ...singleUser,
          record_status: "delete",
        };
      }
      return singleUser;
    });
    setChangedUserStatus(true);
    setData(deleteUsers);
    setIsEditUserModalOpen(false);
  };

  const onFinish = (values) => {
    updateUser(values);
    setIsEditUserModalOpen(false);
  };
  const handleAddUser = () => {
    setNewUserAdding(true);
    form.resetFields();
    setIsEditUserModalOpen(true);
  };
  const updateChanges = () => {
    adminOperation.dispatchUpdateUsers(dispatch)({ users: data });
    adminOperation.dispatchGetUsers(dispatch)();
  };

  const validatePassword = (_, value) => {
    if (newUserAdding && (!value || value.trim() === "")) {
      return Promise.reject("Password is required for new user");
    }
    if (!value) {
      return Promise.resolve();
    }
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+|{}[\]:";'<>?,./]).{8,}$/;
    if (value && value.length >= 8 && value.match(passwordPattern)) {
      return Promise.resolve();
    }
    return Promise.reject(
      "Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one symbol"
    );
  };

  return (
    <div>
      <div>
        <Modal
          title="Edit User"
          open={isEditUserModalOpen}
          onOk={handleEditUserModalOk}
          onCancel={handleEditUserModalCancel}
          footer={null}
        >
          <Form
            form={form}
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your name!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Email ID"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email id!",
                },
              ]}
            >
              <Input disabled={!isEmailFieldEmpty(form)} />
            </Form.Item>

            <Form.Item
              label="Persona"
              name="persona"
              rules={[
                {
                  required: true,
                  message: "Please input persona!",
                },
              ]}
            >
              <Select>
                <Select.Option value="Admin" disabled={persona !== "Admin"}>
                  Admin
                </Select.Option>
                <Select.Option value="Editor">Editor</Select.Option>
                <Select.Option value="Helper">Helper</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Locked"
              name="locked"
              rules={[
                {
                  required: true,
                  message: "Please input locked Status!",
                },
              ]}
            >
              <Select>
                <Select.Option value="UnLocked">UnLocked</Select.Option>
                <Select.Option value="Locked">Locked</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  validator: validatePassword,
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              {!newUserAdding && (
                <Button
                  type="primary"
                  danger
                  onClick={() => deleteUser(form.getFieldsValue())}
                >
                  Delete
                </Button>
              )}
              {"    "}
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
      <Table
        loading={isFetchingUsers}
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 5 }}
      />
      {changedUserStatus && (
        <div>
          <Button type="primary" onClick={undoChanges}>
            {" "}
            Undo{" "}
          </Button>
          {"    "}
          <Button
            type="primary"
            loading={isUpdatingUsers}
            onClick={updateChanges}
          >
            {" "}
            Apply Changes{" "}
          </Button>
        </div>
      )}
      <div className="add_user_button">
        <Button
          disabled={persona !== "Admin"}
          onClick={handleAddUser}
          type="primary"
          style={{
            marginBottom: 16,
          }}
        >
          Add User
        </Button>
      </div>
    </div>
  );
};

export default UserTableComponent;
