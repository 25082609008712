import { call, put, takeLatest, all } from "redux-saga/effects";
import axios from "../../utils/axiosHelper";

import {
  ADMIN_LOGIN,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAILED,
} from "../types/authTypes";

function* fetchAuthLogin(action) {
  try {
    const response = yield call(axios.post, "/auth", action.payload);
    yield put({ type: ADMIN_LOGIN_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: ADMIN_LOGIN_FAILED, payload: error.response.data });
  }
}

function* watchAuthLogin() {
  yield takeLatest(ADMIN_LOGIN, fetchAuthLogin);
}

export function* combinedAuthSaga() {
  yield all([watchAuthLogin()]);
}
export const SagaExports = {
  fetchAuthLogin,
  watchAuthLogin,
};

export const authSaga = {
  watchAuthLogin,
};
