import React, { useEffect, useState } from "react";
import { Carousel } from "antd";
import "./CarouselComponent.css";
import homeSelectors from "../../redux/selectors/homeSelectors";
import { useSelector } from "react-redux";

const CarouselComponent = () => {
  // eslint-disable-next-line no-unused-vars
  const [carouselImages, setCarouselImages] = useState([]);
  const { homeData } = useSelector((state) => ({
    homeData: homeSelectors.homeData(state),
  }));
  useEffect(() => {
    if (homeData !== undefined) {
      setCarouselImages(homeData.images);
    }
  }, [homeData]);
  // eslint-disable-next-line react/prop-types
  const CarouselImageTag = ({ key, path }) => {
    return (
      <div>
        <img key={key} className="scroller_img" src={path} />
      </div>
    );
  };
  return (
    <div className="carousel">
      <Carousel autoplay effect="fade">
        {carouselImages.map((path, index) => (
          <CarouselImageTag key={index} path={path} />
        ))}
      </Carousel>
    </div>
  );
};

export default CarouselComponent;
