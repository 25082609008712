import React, { useEffect, useState } from "react";
import { Table, Modal } from "antd";
import { useSelector } from "react-redux";
import helpSelectors from "../../redux/selectors/helpSelectors";

const QueryViewComponent = () => {
  const [data, setData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({
    content: "",
    subject: "",
  });

  const handleEllipsisClick = (subject, content) => {
    setModalContent({ content: content, subject: subject });
    setModalVisible(true);
  };
  const { isFetchingQuery, queries } = useSelector((state) => ({
    isFetchingQuery: helpSelectors.isFetchingQuery(state),
    queries: helpSelectors.queries(state),
  }));
  useEffect(() => {
    if (queries !== undefined) {
      let queryList = [];
      for (let i = 0; i < queries.length; i++) {
        let item = queries[i];
        item.key = i;
        queryList.push(item);
      }
      setData(queryList);
    }
  }, [queries]);
  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "EmailID",
      dataIndex: "EmailID",
      key: "EmailID",
    },
    {
      title: "Subject",
      dataIndex: "Subject",
      key: "Subject",
      ellipsis: true,
      render: (text, record) => (
        <span
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            color: "blue",
          }}
          onClick={() => handleEllipsisClick(text, record.Message)}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Message",
      dataIndex: "Message",
      key: "Message",
      ellipsis: true,
      render: (text, record) => (
        <span
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            color: "blue",
          }}
          onClick={() => handleEllipsisClick(record.Subject, text)}
        >
          {text}
        </span>
      ),
    },
    {
      title: "CreatedAt",
      dataIndex: "CreatedAt",
      key: "CreatedAt",
      sorter: (a, b) => new Date(a.CreatedAt) - new Date(b.CreatedAt),
    },
  ];
  const sortedDataSource = data
    .slice()
    .sort((a, b) => new Date(a.CreatedAt) - new Date(b.CreatedAt));

  return (
    <div>
      <Modal
        title={modalContent.subject}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        centered={true}
        width={1400}
      >
        <p>{modalContent.content}</p>
      </Modal>
      <Table
        rowKey="key"
        loading={isFetchingQuery}
        columns={columns}
        dataSource={sortedDataSource}
        pagination={{ pageSize: 5 }}
      />
    </div>
  );
};

export default QueryViewComponent;
