import {
  GET_JOURNAL,
  GET_JOURNAL_FAILED,
  SET_JOURNAL,
} from "../types/journalTypes";

const journalReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_JOURNAL: {
      return {
        ...state,
        isFetchingJournal: true,
      };
    }
    case GET_JOURNAL_FAILED: {
      return {
        ...state,
        isFetchingJournal: false,
      };
    }
    case SET_JOURNAL: {
      return {
        ...state,
        journal: action.payload.journal,
        isFetchingJournal: false,
      };
    }
    default:
      return state;
  }
};

export default journalReducer;
