import { createStore, applyMiddleware } from "redux";
//import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducer/index"; // Import your root reducer
import rootSaga from "./sagas/index";
//import { composeWithDevTools } from "redux-devtools-extension";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

// Create Saga Middleware
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

// Enable Redux DevTools Extension
const composeEnhancers = composeWithDevTools({}); //window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Create Redux Store
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

// Run the root saga
sagaMiddleware.run(rootSaga);

export default store;
