import { createSelector } from "reselect";

const selectHelp = (state) => state["raise-query"];

export const selectIsSendingQueryStatus = createSelector(
  [selectHelp],
  (help) => help.isSendingQueryStatus
);

export const selectQuerySubmitted = createSelector(
  [selectHelp],
  (help) => help.querySubmitted
);

export const selectGetQueries = createSelector(
  [selectHelp],
  (help) => help.queries
);

export const selectIsFetchingQuery = createSelector(
  [selectHelp],
  (help) => help.isFetchingQuery
);

const helpSelectors = {
  isSendingQueryStatus: selectIsSendingQueryStatus,
  querySubmitted: selectQuerySubmitted,
  queries: selectGetQueries,
  isFetchingQuery: selectIsFetchingQuery,
};

export default helpSelectors;
