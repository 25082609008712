/* eslint-disable react/prop-types */
import React, { useState } from "react";
import "./PDFViewerComponent.css";
import { Layout, Collapse, Tag, Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
const { Sider, Content } = Layout;

const PDFViewerComponent = ({ metaData }) => {
  const { author, fileUrl, name, tag, updatedAt } = metaData;
  const [collapsed, setCollapsed] = useState(false);
  const generateRandomLightColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  const downloadPdf = () => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.target = "_blank";
    link.download = fileUrl.split("/")[-1];
    link.click();
  };
  function getPdfMetaData() {
    const items = [
      {
        key: "1",
        label: "Title",
        children: <span className="metadata_text">{name}</span>,
      },
      {
        key: "2",
        label: "Author",
        children: <span className="metadata_text">{author}</span>,
      },
      {
        key: "3",
        label: "Tag",
        children: (
          <div>
            {tag.map((item) => (
              // eslint-disable-next-line react/jsx-key
              <Tag color={generateRandomLightColor()}>{item}</Tag>
            ))}
          </div>
        ),
      },
      {
        key: "4",
        label: "Last Updated",
        children: (
          <span className="metadata_text">
            {updatedAt.split("T")[0]} {updatedAt.split("T")[1].split(".")[0]}
          </span>
        ),
      },
      {
        key: "5",
        label: "Download Journal",
        children: (
          <Button
            type="primary"
            shape="round"
            onClick={downloadPdf}
            icon={<DownloadOutlined />}
            size="large"
          >
            Download
          </Button>
        ),
      },
    ];
    return items;
  }
  return (
    <Layout className="pdfLayoutStyle">
      <Layout>
        <Content className="pdfContentStyle">
          <iframe
            src={fileUrl}
            width="100%"
            height="590px"
            title="PDF Viewer"
          ></iframe>
        </Content>
      </Layout>
      <Sider
        onCollapse={(value) => setCollapsed(value)}
        className="pdfSiderStyle"
        collapsedWidth={100}
        width="25%"
        theme="light"
        collapsible
        collapsed={collapsed}
      >
        <Collapse items={getPdfMetaData()} defaultActiveKey={["1"]} />
      </Sider>
    </Layout>
  );
};

export default PDFViewerComponent;
