import React, { useEffect, useState } from "react";
import "./AboutContainer.css";
import { useSelector } from "react-redux";
import EditorsViewComponent from "../../component/EditorsViewComponent/EditorsViewComponent";
import homeSelectors from "../../redux/selectors/homeSelectors";

const AboutContainer = () => {
  const [editorsData, setEditorsData] = useState([]);
  const { editors } = useSelector((state) => ({
    editors: homeSelectors.editors(state),
  }));
  useEffect(() => {
    if (editors !== undefined && editors.length > 0) {
      setEditorsData(editors);
    }
  }, [editors]);
  return (
    <div>
      {editorsData.map((editor, index) => (
        <React.Fragment key={index}>
          <EditorsViewComponent
            key={index}
            name={editor.name}
            role={editor.role}
            about={editor.about}
            photourl={editor.photourl}
          />
          {index < editors.length - 1 && <hr />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default AboutContainer;
