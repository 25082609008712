/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import "./ArchiveContainer.css";
import { Input, Select, Button, Space, Card, Tag, Modal, Result } from "antd";
import journalSelectors from "../../redux/selectors/journalSelectors";
import { SearchOutlined } from "@ant-design/icons";
import { GrPowerReset } from "react-icons/gr";
import journalOperations from "../../redux/operations/journalOperations";
import PDFViewerComponent from "../../component/PDFViewerComponent/PDFViewerComponent";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const JournalContainer = () => {
  const dispatch = useDispatch();
  const [tagList, setTagList] = useState([]);
  const navigate = useNavigate();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const id = query.get("id");
  const [isInvalidURLModalOpen, setIsInvalidURLModalOpen] = useState(false);
  const showErrorModal = () => {
    setIsInvalidURLModalOpen(true);
  };
  const handleInvalidURLModelOk = () => {
    navigate(`/archive`);
    setCurrentJournal();
    setInputJounalName("");
    setInputJounalTag(null);
    setIsInvalidURLModalOpen(false);
  };
  const [currentJournal, setCurrentJournal] = useState();
  const [getJournal, setJournal] = useState([]);
  const [inputJounalName, setInputJounalName] = useState("");
  const [inputJounalTag, setInputJounalTag] = useState(null);
  const [searchJournal, setSearchJournal] = useState([]);
  const { journalData, isFetchingJournal } = useSelector((state) => ({
    journalData: journalSelectors.journal(state),
    isFetchingJournal: journalSelectors.isFetchingJournal(state),
  }));
  useEffect(() => {
    journalOperations.dispatchGetJournal(dispatch)();
  }, [dispatch]);
  useEffect(() => {
    if (id !== null && id !== undefined) {
      if (journalData !== null && journalData !== undefined) {
        const idJournal = journalData.filter((obj) => {
          let _idMatches = true;
          if (id) {
            _idMatches = obj._id.toLowerCase().includes(id.toLowerCase());
          }
          return _idMatches;
        });
        if (idJournal.length == 1) {
          setCurrentJournal(idJournal[0]);
        } else {
          showErrorModal();
        }
      }
    } else {
      setCurrentJournal();
      setInputJounalName("");
      setInputJounalTag(null);
    }
  }, [id, journalData, navigate]);
  useEffect(() => {
    if (journalData !== undefined && journalData.length !== 0) {
      setSearchJournal(journalData);
      setJournal(journalData);
      let list_tags = [];
      for (let journal_i = 0; journal_i < journalData.length; journal_i++) {
        let journal_tag_list = journalData[journal_i].tag;
        for (
          let journal_tag = 0;
          journal_tag < journal_tag_list.length;
          journal_tag++
        ) {
          let tag = journal_tag_list[journal_tag];
          if (!list_tags.some((item) => item.value === tag)) {
            list_tags.push({
              value: tag,
              label: tag,
            });
          }
        }
      }
      setTagList(list_tags);
    }
  }, [journalData]);

  const generateRandomLightColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const handleJournalNameChange = (event) => {
    setInputJounalName(event.target.value);
  };
  const handleJournalTagChange = (value) => {
    setInputJounalTag(value);
  };
  function filterJournals(name, tag) {
    return journalData.filter((obj) => {
      let nameMatches = true;
      let tagsMatches = true;

      if (name) {
        nameMatches = obj.name.toLowerCase().includes(name.toLowerCase());
      }

      if (tag) {
        tagsMatches = obj.tag.includes(tag);
      }

      return nameMatches && tagsMatches;
    });
  }
  const onFinish = () => {
    setCurrentJournal();
    setSearchJournal(filterJournals(inputJounalName, inputJounalTag));
  };

  const resetSearchBar = () => {
    setSearchJournal(journalData);
    setInputJounalName("");
    setInputJounalTag(null);
    setCurrentJournal();
  };

  const openPdf = (journalCardData) => {
    setCurrentJournal(journalCardData);
    navigate(`/archive?id=${journalCardData._id}`);
  };

  const JournalCard = ({ journalCardData }) => {
    const journalTitle = journalCardData.name;
    const journalAuthor = journalCardData.author;
    const journalUpdatedOn = journalCardData.updatedAt.split("T")[0];
    const journalTag = journalCardData.tag;

    return (
      <div>
        {journalTitle && (
          <Card
            title={journalTitle}
            size="small"
            onClick={() => openPdf(journalCardData)}
            style={{
              width: "350",
              border: "2px solid blue",
              cursor: "pointer",
            }}
          >
            {journalAuthor && (
              <p>
                <b>Author</b> - {journalAuthor}
              </p>
            )}
            {journalUpdatedOn && (
              <p>
                <b>Updated</b> - {journalUpdatedOn}
              </p>
            )}
            {journalTag && (
              <p>
                <b>Tags</b> -{" "}
                {journalTag.map((item) => (
                  <Tag color={generateRandomLightColor()}>{item}</Tag>
                ))}
              </p>
            )}
          </Card>
        )}
      </div>
    );
  };

  return (
    <div className="journal_container">
      <div className="journal_search_container">
        <Input
          className="journal_search_input"
          addonBefore="Journal"
          value={inputJounalName}
          placeholder="Journal Name"
          onChange={handleJournalNameChange}
        />
        <Select
          showSearch
          loading={isFetchingJournal}
          allowClear={true}
          value={inputJounalTag}
          onChange={handleJournalTagChange}
          className="journal_search_input"
          placeholder="Journal Tag"
          optionFilterProp="children"
          options={tagList}
        />
        <Button
          className="journal_search"
          type="primary"
          onClick={onFinish}
          icon={<SearchOutlined />}
        >
          Search
        </Button>
        <Button
          className="journal_search"
          shape="circle"
          onClick={resetSearchBar}
          icon={<GrPowerReset />}
        />
      </div>
      <Modal
        title="Invalid Journal ID"
        open={isInvalidURLModalOpen}
        onOk={handleInvalidURLModelOk}
        footer={null}
      >
        <p>Check with Journal Author. Whether Journal is removed or not.</p>
        <div style={{ textAlign: "right" }}>
          <Button type="primary" onClick={handleInvalidURLModelOk}>
            OK
          </Button>
        </div>
      </Modal>
      {getJournal.length === 0 && (
        <Result
          status="404"
          title=""
          subTitle="Sorry, Currently there are no archives available"
        />
      )}
      {!currentJournal && (
        <div className="journal_list">
          <Space size={[16, 16]} wrap>
            {searchJournal &&
              searchJournal.map((item) => (
                <JournalCard journalCardData={item} />
              ))}
          </Space>
        </div>
      )}
      {currentJournal && (
        <div className="journal_viewer_div">
          <PDFViewerComponent metaData={currentJournal} />
        </div>
      )}
    </div>
  );
};

export default JournalContainer;
