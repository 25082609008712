import {
  GET_USERS,
  UPDATE_USERS,
  UPLOAD_LOGO,
  UPLOAD_CAROUSEL,
  UPDATE_EDITORS,
  UPLOAD_FILE,
  UPLOAD_JOURNAL,
  UPLOAD_IMAGES,
  UPDATE_SCROLL_TEXT,
  UPDATE_AIM_SCOPE,
} from "../types/adminTypes";

const getUsers = () => {
  return {
    type: GET_USERS,
  };
};

const updateUsers = (userList) => {
  return {
    type: UPDATE_USERS,
    payload: userList,
  };
};

const uploadImages = (images) => {
  return {
    type: UPLOAD_IMAGES,
    payload: images,
  };
};

const uploadLogo = (logo) => {
  return {
    type: UPLOAD_LOGO,
    payload: logo,
  };
};

const uploadJournal = (journalData) => {
  return {
    type: UPLOAD_JOURNAL,
    payload: journalData,
  };
};

const updateScrollText = (text) => {
  return {
    type: UPDATE_SCROLL_TEXT,
    payload: text,
  };
};

const uploadFile = (file) => {
  return {
    type: UPLOAD_FILE,
    payload: file,
  };
};

const uploadCarousel = (carousel) => {
  return {
    type: UPLOAD_CAROUSEL,
    payload: carousel,
  };
};

const updateEditors = (editorsData) => {
  return {
    type: UPDATE_EDITORS,
    payload: editorsData,
  };
};

const updateAimScope = (aimScopeData) => {
  return {
    type: UPDATE_AIM_SCOPE,
    payload: aimScopeData,
  };
};

export const adminAction = {
  getUsers,
  updateUsers,
  uploadLogo,
  uploadCarousel,
  updateEditors,
  uploadFile,
  uploadJournal,
  uploadImages,
  updateScrollText,
  updateAimScope,
};
