import { helpAction } from "../actions/helpAction";
const { sendQuery, getQuery } = helpAction;

const dispatchSendQuery = (dispatch) => (query) => {
  dispatch(sendQuery(query));
};

const dispatchGetQuery = (dispatch) => () => {
  dispatch(getQuery());
};

const helpOperations = {
  dispatchSendQuery,
  dispatchGetQuery,
};

export default helpOperations;
