import React, { useState, useEffect } from "react";
import "./AnnouncementComponent.css";
import { Card, Row, Col } from "antd";
import homeSelectors from "../../redux/selectors/homeSelectors";
import { useSelector } from "react-redux";

const AnnouncementComponent = () => {
  const [lastUpdatedJournal, setLastUpdatedJournal] = useState({});
  const { homeData } = useSelector((state) => ({
    homeData: homeSelectors.homeData(state),
  }));
  useEffect(() => {
    if (homeData !== undefined) {
      setLastUpdatedJournal(homeData.journal);
    }
  }, [homeData]);
  return (
    <div className="announcement_list_card">
      <Row className="announcement_row">
        <Col span={24}>
          <Card
            className="announcement_card"
            title="Last Uploaded Journal"
            size="small"
            extra={lastUpdatedJournal.journal_updateDate}
          >
            <p>{lastUpdatedJournal.journal_title}</p>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AnnouncementComponent;
