import { call, put, takeLatest, all } from "redux-saga/effects";
import axios from "../../utils/axiosHelper";

import {
  SEND_QUERY,
  SEND_QUERY_FAILED,
  SEND_QUERY_SUCCESS,
  GET_QUERY,
  GET_QUERY_FAILED,
  SET_QUERY,
} from "../types/helpTypes";

function* sendQuery(action) {
  try {
    const response = yield call(axios.post, "/query", action.payload);
    yield put({ type: SEND_QUERY_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: SEND_QUERY_FAILED, payload: error.message });
  }
}

function* getQuery(action) {
  try {
    const response = yield call(axios.get, "/query", action.payload);
    yield put({ type: SET_QUERY, payload: response.data });
  } catch (error) {
    yield put({ type: GET_QUERY_FAILED, payload: error.message });
  }
}

function* watchGetQuery() {
  yield takeLatest(GET_QUERY, getQuery);
}

function* watchSendQuery() {
  yield takeLatest(SEND_QUERY, sendQuery);
}

export function* combinedHelpSaga() {
  yield all([watchSendQuery(), watchGetQuery()]);
}
export const SagaExports = {
  sendQuery,
  watchSendQuery,
  getQuery,
  watchGetQuery,
};

export const helpSaga = {
  watchSendQuery,
  watchGetQuery,
};
