import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import "./AdminContainer.css";
const { Sider, Content } = Layout;
import { FaUserAlt, FaImage, FaEdit } from "react-icons/fa";
import { TbMessageReport } from "react-icons/tb";
import { IoMdJournal } from "react-icons/io";
import { RiSlideshow2Fill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { FaTextWidth, FaMagnifyingGlass } from "react-icons/fa6";
import UserTableComponent from "../../component/UserTableComponent/UserTableComponent";
import adminOperations from "../../redux/operations/adminOperations";
import helpOperations from "../../redux/operations/helpOperations";
import LogoUploadComponent from "../../component/LogoUploadComponent/LogoUploadComponent";
import EditorUserComponent from "../../component/EditorUserComponent/EditorUserComponent";
import QueryViewComponent from "../../component/QueryViewComponent/QueryViewComponent";
import CarouselEditComponent from "../../component/CarouselEditComponent/CarouselEditComponent";
import JournalUploadComponent from "../../component/JournalUploadComponent/JournalUploadComponent";
import MarqueeComponent from "../../component/MarqueeComponent/MarqueeComponent";
import AimEditComponent from "../../component/AimEditComponent/AimEditComponent";

const AdminContainer = () => {
  const items = [
    {
      label: "Users",
      key: "1",
      icon: <FaUserAlt />,
    },
    {
      label: "Logo",
      key: "2",
      icon: <FaImage />,
    },
    {
      label: "Editors",
      key: "3",
      icon: <FaEdit />,
    },
    {
      label: "Queries",
      key: "4",
      icon: <TbMessageReport />,
    },
    {
      label: "Journals",
      key: "5",
      icon: <IoMdJournal />,
    },
    {
      label: "Carousel",
      key: "6",
      icon: <RiSlideshow2Fill />,
    },
    {
      label: "Scroll Text",
      key: "7",
      icon: <FaTextWidth />,
    },
    {
      label: "Aim & Scope",
      key: "8",
      icon: <FaMagnifyingGlass />,
    },
  ];
  const dispatch = useDispatch();
  useEffect(() => {
    adminOperations.dispatchGetUsers(dispatch)();
    helpOperations.dispatchGetQuery(dispatch)();
  }, [dispatch]);
  const [selectedMenu, setSelectedMenu] = useState("1");
  function setComponent() {
    switch (selectedMenu) {
      case "1":
        return <UserTableComponent />;
      case "2":
        return <LogoUploadComponent />;
      case "3":
        return <EditorUserComponent />;
      case "4":
        return <QueryViewComponent />;
      case "5":
        return <JournalUploadComponent />;
      case "6":
        return <CarouselEditComponent />;
      case "7":
        return <MarqueeComponent />;
      case "8":
        return <AimEditComponent />;
    }
  }
  const onClick = (e) => {
    setSelectedMenu(e.key);
  };
  return (
    <div className="adminconsole_div">
      <Layout className="adminconsole_layout">
        <Sider width="25%" theme="light" className="adminconsole_sider">
          <Menu
            className="adminconsole_menu"
            onClick={onClick}
            selectedKeys={selectedMenu}
            mode="vertical"
            theme="light"
            items={items}
          />
        </Sider>
        <Content className="adminconsole_content">{setComponent()}</Content>
      </Layout>
    </div>
  );
};
export default AdminContainer;
