import {
  GET_HOME_DATA,
  GET_HOME_DATA_FAILED,
  GET_HOME_DATA_SUCCESS,
  UPDATE_EDITORS,
  UPDATE_EDITORS_FAILED,
  UPDATE_EDITORS_SUCCESS,
  GET_EDITORS,
  GET_EDITORS_FAILED,
  SET_EDITORS,
} from "../types/homeTypes";

const homeReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_HOME_DATA: {
      return {
        ...state,
        isGettingHomeData: true,
      };
    }
    case GET_HOME_DATA_SUCCESS: {
      return {
        ...state,
        homeData: action.payload,
        isGettingHomeData: false,
      };
    }
    case GET_HOME_DATA_FAILED: {
      return {
        ...state,
        error: action.payload.error,
        isGettingHomeData: false,
      };
    }
    case UPDATE_EDITORS: {
      return {
        ...state,
        isUpdatingEditors: true,
      };
    }
    case UPDATE_EDITORS_SUCCESS: {
      return {
        ...state,
        isUpdatingEditors: false,
      };
    }
    case UPDATE_EDITORS_FAILED: {
      return {
        ...state,
        isUpdatingEditors: false,
      };
    }
    case GET_EDITORS: {
      return {
        ...state,
        isFetchingEditors: true,
      };
    }
    case SET_EDITORS: {
      return {
        ...state,
        editors: action.payload.data,
        isFetchingEditors: false,
      };
    }
    case GET_EDITORS_FAILED: {
      return {
        ...state,
        isFetchingEditors: false,
      };
    }
    default:
      return state;
  }
};

export default homeReducer;
