/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./LayoutContainer.css";
import { Layout, Menu, Button, Modal } from "antd";
const { Header, Content, Footer } = Layout;
import RaiseQueryComponent from "../../component/RaiseQueryComponent/RaiseQueryComponent";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import HomeContainer from "../HomeContainer/HomeContainer";
import AboutContainer from "../AboutContainer/AboutContainer";
import AdminLoginDrawerComponent from "../../component/AdminLoginDrawerComponent/AdminLoginDrawerComponent";
import AdminContainer from "../../container/AdminContainer/AdminContainer";
import ArchiveContainer from "../ArchiveContainer/ArchiveContainer";
import homeOperations from "../../redux/operations/homeOperations";
import journalOperations from "../../redux/operations/journalOperations";
import SubmissionContainer from "../SubmissionContainer/SubmissionContainer";
import SessionHandler from "../../utils/SessionHandler";
import AimContainer from "../AimContainer/AimContainer";
const { getSession, clearSession, getSessionTimeOut, setSessionTimeOut } =
  SessionHandler;

const items = [
  {
    key: "1",
    label: "Home",
  },
  {
    key: "2",
    label: "Aim & Scope",
  },
  {
    key: "3",
    label: "Submission",
  },
  {
    key: "4",
    label: "Archives",
  },
  {
    key: "5",
    label: "Editors",
  },
  {
    key: "6",
    label: "Help",
    children: [
      {
        key: "7",
        label: "Raise Query",
      },
    ],
  },
];

const adminMenuItem = {
  key: "8",
  label: "Admin Console",
};

const LayoutContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [logoutTimeOutModal, setLogoutTimeOutModal] = useState(false);
  const [menuItem, setMenuItem] = useState(items);
  const [menuKey, setmenuKey] = useState("1");
  const [open, setOpen] = useState(false);
  const [adminLoginData, setAdminLoginData] = useState({});
  const [adminLoginStatus, setAdminLoginStatus] = useState(
    getSession().token !== null
  );
  useEffect(() => {
    homeOperations.dispatchHomeData(dispatch)();
    homeOperations.dispatchGetEditors(dispatch)();
    journalOperations.dispatchGetJournal(dispatch)();
    if ((getSessionTimeOut() !== undefined) & (getSessionTimeOut() !== null)) {
      if (getSessionTimeOut()) {
        setLogoutTimeOutModal(true);
      }
    }
  }, []);

  const adminLoginOrLogout = () => {
    if (adminLoginStatus) {
      setMenuItem(items);
      setAdminLoginStatus(false);
      setAdminLoginData({});
      clearSession();
    } else {
      setOpen(true);
    }
  };

  useEffect(() => {
    setOpen(false);
  }, [adminLoginData]);

  useEffect(() => {
    if (adminLoginStatus) {
      setTimeout(() => {
        setSessionTimeOut(true);
        setLogoutTimeOutModal(true);
      }, 60 * 60 * 1000);

      const updatedMenu = [...items, adminMenuItem];
      setMenuItem(updatedMenu);
    } else {
      setMenuItem(items);
    }
  }, [adminLoginStatus]);

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setmenuKey("1");
        return;
      case "/aim":
        setmenuKey("2");
        return;
      case "/submission":
        setmenuKey("3");
        return;
      case "/archive":
        setmenuKey("4");
        return;
      case "/editors":
        setmenuKey("5");
        return;
      case "/raise-query":
        setmenuKey("7");
        return;
      case "/admin-console":
        setmenuKey("8");
        return;
    }
  }, [location]);

  const handleOk = () => {
    setLogoutTimeOutModal(false);
    clearSession();
  };

  function menuOnClick(e) {
    setmenuKey(e.key);
    switch (e.key) {
      case "1":
        navigate("/");
        return;
      case "2":
        navigate("/aim");
        return;
      case "3":
        navigate("/submission");
        return;
      case "4":
        navigate("/archive");
        return;
      case "5":
        navigate("/editors");
        return;
      case "6":
        return;
      case "7":
        navigate("/raise-query");
        return;
      case "8":
        if (adminLoginStatus) {
          navigate("/admin-console");
        } else {
          navigate("/");
        }
        return;
    }
  }

  // eslint-disable-next-line react/prop-types
  const PrivateRoute = ({ children }) => {
    if (adminLoginStatus) {
      return children;
    }
    return <Navigate to="/" />;
  };
  function getLogoUrl(){
    if (process.env.ENV === 'dev'){
      return "https://storage.googleapis.com/iomjelm-dev.appspot.com/logo/logo.jpeg"
    }
    else if(process.env.ENV === 'uat'){
      return "https://storage.googleapis.com/iomjelm-uat.appspot.com/logo/logo.jpeg"
    }
    else{
      return "https://storage.googleapis.com/iomjelm-prod.appspot.com/logo/logo.jpeg"
    }
  }
  return (
    <div className="LayoutContainer">
      <Layout className="Layout">
        <div>
          <Header className="Header" collapsible="true" theme="light">
            <div className="demo-logo" onClick={() => navigate("/")}>
              <img
                src={getLogoUrl()}
                className="logo"
              />
            </div>
            <Menu
              theme="light"
              mode="horizontal"
              selectedKeys={menuKey}
              defaultSelectedKeys={["1"]}
              items={menuItem}
              className="Menu_bar"
              onClick={menuOnClick}
            />
            <div>
              <Button type="primary" onClick={adminLoginOrLogout} ghost>
                {adminLoginStatus === false ? "Admin Login" : "Logout"}
              </Button>
            </div>
          </Header>
          <div className="header_bar" />
        </div>
        <div className="content_container">
          <Content className="content">
            <div className="content-div">
              <Modal
                title="Session Timeout"
                open={logoutTimeOutModal}
                closeIcon={null}
                maskClosable={false}
                keyboard={false}
                footer={[
                  <Button key="ok" type="primary" onClick={handleOk}>
                    OK
                  </Button>,
                ]}
              >
                <p>Dear {getSession().name}, Please login again.</p>
              </Modal>
              <AdminLoginDrawerComponent
                {...{ open, setOpen, setAdminLoginData }}
              />
              <Routes>
                <Route path="/" element={<HomeContainer />} />
                <Route path="/aim" element={<AimContainer />} />
                <Route path="/submission" element={<SubmissionContainer />} />
                <Route path="/archive" element={<ArchiveContainer />} />
                <Route path="/editors" element={<AboutContainer />} />
                <Route path="/raise-query" element={<RaiseQueryComponent />} />
                <Route
                  path="/admin-console"
                  element={
                    <PrivateRoute>
                      <AdminContainer />
                    </PrivateRoute>
                  }
                />
              </Routes>
            </div>
          </Content>

          <Footer className="Footer">
            <b>
              International Online Monthly Journal of English Literary Magna ©
              {new Date().getFullYear()}
            </b>{" "}
            Created by YV
          </Footer>
        </div>
      </Layout>
    </div>
  );
};
export default LayoutContainer;
