import {
  SEND_QUERY,
  SEND_QUERY_FAILED,
  SEND_QUERY_SUCCESS,
  GET_QUERY,
  GET_QUERY_FAILED,
  SET_QUERY,
} from "../types/helpTypes";

const helpReducer = (state = {}, action) => {
  switch (action.type) {
    case SEND_QUERY: {
      return {
        ...state,
        isSendingQueryStatus: true,
      };
    }
    case SEND_QUERY_SUCCESS: {
      return {
        ...state,
        querySubmitted: true,
        isSendingQueryStatus: false,
      };
    }
    case SEND_QUERY_FAILED: {
      return {
        ...state,
        error: action.payload.error,
        querySubmitted: false,
        isSendingQueryStatus: false,
      };
    }
    case GET_QUERY: {
      return {
        ...state,
        isFetchingQuery: true,
      };
    }
    case GET_QUERY_FAILED: {
      return {
        ...state,
        isFetchingQuery: false,
      };
    }
    case SET_QUERY: {
      return {
        ...state,
        queries: action.payload.queries,
        isFetchingQuery: false,
      };
    }
    default:
      return state;
  }
};

export default helpReducer;
