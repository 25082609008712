import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Space, message } from "antd";
import "./AimEditComponent.css";
import adminOperations from "../../redux/operations/adminOperations";
import adminSelectors from "../../redux/selectors/adminSelectors";
import homeSelectors from "../../redux/selectors/homeSelectors";

const AimEditComponent = () => {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [aimValue, setAimValue] = useState({
    aim: [],
  });
  const { isUpdatingAimScope, homeData, aimScopeStatus } = useSelector(
    (state) => ({
      aimScopeStatus: adminSelectors.aimScopeStatus(state),
      isUpdatingAimScope: adminSelectors.isUpdatingAimScope(state),
      homeData: homeSelectors.homeData(state),
    })
  );
  useEffect(() => {
    if (homeData !== undefined) {
      if ("aim_scope" in homeData) {
        setAimValue({ aim: homeData.aim_scope });
        form.setFieldsValue({ aim: homeData.aim_scope });
      }
    }
  }, [homeData]);
  useEffect(() => {
    if (aimScopeStatus !== undefined) {
      if (aimScopeStatus === "Updated Successfully") {
        message.success("Aim & Scope Updated Successfully");
      } else {
        message.success("Aim & Scope Updating Failed");
      }
    }
  }, [aimScopeStatus]);
  const onFinish = (values) => {
    setAimValue(values);
    adminOperations.dispatchUpdateAimScope(dispatch)(values);
  };
  return (
    <div className="aim_edit_component">
      <Form
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        form={form}
        style={{
          maxWidth: 900,
        }}
        initialValues={aimValue}
        autoComplete="off"
      >
        <Form.List name="aim">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{
                    display: "flex",
                    marginBottom: 8,
                  }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, "heading"]}
                    rules={[
                      {
                        required: true,
                        message: "Missing Sub Heading",
                      },
                    ]}
                    className="sub_heading_box"
                  >
                    <Input placeholder="Sub Heading" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "desciption"]}
                    rules={[
                      {
                        required: true,
                        message: "Missing Description",
                      },
                    ]}
                    className="desc_box"
                  >
                    <TextArea rows={2} placeholder="Description" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isUpdatingAimScope}>
            Apply Changes
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AimEditComponent;
