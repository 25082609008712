import React, { useState, useEffect } from "react";
import { Input, Button, message } from "antd";
import "./MarqueeComponent.css";
import homeSelectors from "../../redux/selectors/homeSelectors";
import adminSelectors from "../../redux/selectors/adminSelectors";
import adminOperations from "../../redux/operations/adminOperations";
import { useSelector, useDispatch } from "react-redux";

const MarqueeComponent = () => {
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const [updatedText, setUpdatedText] = useState("");
  const { homeData, isUpdatingScrollText, scroll_text_update_status } =
    useSelector((state) => ({
      homeData: homeSelectors.homeData(state),
      isUpdatingScrollText: adminSelectors.isUpdatingScrollText(state),
      scroll_text_update_status:
        adminSelectors.scroll_text_update_status(state),
    }));
  useEffect(() => {
    if (homeData !== undefined) {
      setUpdatedText(homeData.scroll_text);
    }
  }, [homeData]);

  useEffect(() => {
    if (scroll_text_update_status !== undefined) {
      if (scroll_text_update_status === "Updated Successfully") {
        message.success("Scroll Text Updated");
      } else {
        message.error("Scroll Text Update Failed");
      }
    }
  }, [scroll_text_update_status]);

  const handleTextChange = (e) => {
    setUpdatedText(e.target.value);
  };

  const updateScrollText = () => {
    const reponse = {
      scroll_text: updatedText,
    };
    adminOperations.dispatchUpdateScrollText(dispatch)(reponse);
  };

  return (
    <div>
      <div className="marquee_textbox">
        <TextArea rows={4} value={updatedText} onChange={handleTextChange} />
      </div>
      <Button
        type="primary"
        loading={isUpdatingScrollText}
        onClick={updateScrollText}
      >
        Apply Changes
      </Button>
    </div>
  );
};

export default MarqueeComponent;
