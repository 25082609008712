import {
  GET_USERS,
  SET_USERS,
  GET_USERS_FAILED,
  UPDATE_USERS,
  UPDATE_USERS_FAILED,
  UPDATE_USERS_SUCCESS,
  UPLOAD_LOGO,
  UPLOAD_LOGO_FAILED,
  UPLOAD_LOGO_SUCCESS,
  UPLOAD_CAROUSEL,
  UPLOAD_CAROUSEL_SUCCESS,
  UPLOAD_CAROUSEL_FAILED,
  UPDATE_EDITORS,
  UPDATE_EDITORS_FAILED,
  UPDATE_EDITORS_SUCCESS,
  UPLOAD_FILE,
  UPLOAD_FILE_FAILED,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_JOURNAL,
  UPLOAD_JOURNAL_FAILED,
  UPLOAD_JOURNAL_SUCCESS,
  UPLOAD_IMAGES,
  UPLOAD_IMAGES_FAILED,
  UPLOAD_IMAGES_SUCCESS,
  UPDATE_SCROLL_TEXT,
  UPDATE_SCROLL_TEXT_FAILED,
  UPDATE_SCROLL_TEXT_SUCCESS,
  UPDATE_AIM_SCOPE,
  UPDATE_AIM_SCOPE_SUCCESS,
  UPDATE_AIM_SCOPE_FAILED,
} from "../types/adminTypes";

const adminReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_USERS: {
      return {
        ...state,
        isFetchingUsers: true,
      };
    }
    case SET_USERS: {
      return {
        ...state,
        listOfUsers: action.payload.users,
        isFetchingUsers: false,
      };
    }
    case GET_USERS_FAILED: {
      return {
        ...state,
        isFetchingUsers: false,
      };
    }
    case UPDATE_USERS: {
      return {
        ...state,
        isUpdatingUsers: true,
      };
    }
    case UPDATE_USERS_SUCCESS: {
      return {
        ...state,
        isUpdatingUsers: false,
      };
    }
    case UPDATE_USERS_FAILED: {
      return {
        ...state,
        isFetchingUsers: false,
      };
    }
    case UPLOAD_LOGO: {
      return {
        ...state,
        isUploadingLogo: true,
      };
    }
    case UPLOAD_LOGO_SUCCESS: {
      return {
        ...state,
        isUploadingLogo: false,
        logoUpload: action.payload,
      };
    }
    case UPLOAD_LOGO_FAILED: {
      return {
        ...state,
        isUploadingLogo: false,
      };
    }
    case UPLOAD_CAROUSEL: {
      return {
        ...state,
        isUploadingCarousel: true,
      };
    }
    case UPLOAD_CAROUSEL_SUCCESS: {
      return {
        ...state,
        uploadCarousel: action.payload.upload,
        isUploadingCarousel: false,
      };
    }
    case UPLOAD_CAROUSEL_FAILED: {
      return {
        ...state,
        uploadCarousel: action.payload.upload,
        isUploadingCarousel: false,
      };
    }
    case UPDATE_EDITORS: {
      return {
        ...state,
        isUpdatingEditors: true,
      };
    }
    case UPDATE_EDITORS_SUCCESS: {
      return {
        ...state,
        updateEditors: {
          updatedEditor: action.payload.updatedEditor,
          insertedEditor: action.payload.insertedEditor,
          deletedEditor: action.payload.deletedEditor,
        },
        isUpdatingEditors: false,
      };
    }
    case UPDATE_EDITORS_FAILED: {
      return {
        ...state,
        isUpdatingEditors: false,
      };
    }
    case UPLOAD_FILE: {
      return {
        ...state,
        isUploadingFile: true,
      };
    }
    case UPLOAD_FILE_SUCCESS: {
      return {
        ...state,
        isUploadingFile: false,
        fileUpload: {
          name: action.payload.name,
          fileUrl: action.payload.publicUrl,
        },
      };
    }
    case UPLOAD_FILE_FAILED: {
      return {
        ...state,
        isUploadingFile: false,
      };
    }
    case UPLOAD_JOURNAL: {
      return {
        ...state,
        isUploadingJournal: true,
      };
    }
    case UPLOAD_JOURNAL_SUCCESS: {
      return {
        ...state,
        isUploadingJournal: false,
        journalUpload: {
          updatedJournal: action.payload.updatedJournal,
          insertedJournal: action.payload.insertedJournal,
          deletedJournal: action.payload.deletedJournal,
        },
      };
    }
    case UPLOAD_JOURNAL_FAILED: {
      return {
        ...state,
        isUploadingJournal: false,
      };
    }
    case UPLOAD_IMAGES: {
      return {
        ...state,
        isUploadingImages: true,
      };
    }
    case UPLOAD_IMAGES_SUCCESS: {
      return {
        ...state,
        isUploadingImages: false,
        imagesUpload: action.payload,
      };
    }
    case UPLOAD_IMAGES_FAILED: {
      return {
        ...state,
        isUploadingImages: false,
      };
    }
    case UPDATE_SCROLL_TEXT: {
      return {
        ...state,
        isUpdatingScrollText: true,
      };
    }
    case UPDATE_SCROLL_TEXT_SUCCESS: {
      return {
        ...state,
        isUpdatingScrollText: false,
        scroll_text_update_status: action.payload.update_status,
      };
    }
    case UPDATE_SCROLL_TEXT_FAILED: {
      return {
        ...state,
        isUpdatingScrollText: false,
      };
    }
    case UPDATE_AIM_SCOPE: {
      return {
        ...state,
        isUpdatingAimScope: true,
      };
    }
    case UPDATE_AIM_SCOPE_SUCCESS: {
      return {
        ...state,
        isUpdatingAimScope: false,
        aimScopeStatus: action.payload.update_status,
      };
    }
    case UPDATE_AIM_SCOPE_FAILED: {
      return {
        ...state,
        isUpdatingAimScope: false,
      };
    }
    default:
      return state;
  }
};

export default adminReducer;
