import { all } from "redux-saga/effects";
import { combinedAuthSaga } from "./authSaga";
import { combinedAdminSaga } from "./adminSaga";
import { combinedHelpSaga } from "./helpSaga";
import { combinedHomeSaga } from "./homeSaga";
import { combinedJournalSaga } from "./journalSaga";

export default function* rootSaga() {
  yield all([
    combinedAuthSaga(),
    combinedAdminSaga(),
    combinedHelpSaga(),
    combinedHomeSaga(),
    combinedJournalSaga(),
  ]);
}
