import { GET_HOME_DATA, UPDATE_EDITORS, GET_EDITORS } from "../types/homeTypes";

const getHomeData = () => {
  return {
    type: GET_HOME_DATA,
  };
};

const getEditors = () => {
  return {
    type: GET_EDITORS,
  };
};

const updateEditors = (editorsData) => {
  return {
    type: UPDATE_EDITORS,
    payload: editorsData,
  };
};

export const homeAction = {
  getHomeData,
  updateEditors,
  getEditors,
};
