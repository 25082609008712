import { createSelector } from "reselect";

const selectJournal = (state) => state.journal;

export const selectJournalData = createSelector(
  [selectJournal],
  (journal) => journal.journal
);

export const selectIsFetchingJournal = createSelector(
  [selectJournal],
  (journal) => journal.isFetchingJournal
);

const journalSelectors = {
  isFetchingJournal: selectIsFetchingJournal,
  journal: selectJournalData,
};

export default journalSelectors;
