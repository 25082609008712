import {
  GET_JOURNAL,
  GET_JOURNAL_FAILED,
  SET_JOURNAL,
} from "../types/journalTypes";
import { call, put, takeLatest, all } from "redux-saga/effects";
import axios from "../../utils/axiosHelper";

function* fetchJournal(action) {
  try {
    const response = yield call(axios.get, "/journal", action.payload);
    yield put({ type: SET_JOURNAL, payload: response.data });
  } catch (error) {
    yield put({ type: GET_JOURNAL_FAILED, payload: error.message });
  }
}

function* watchFetchJournal() {
  yield takeLatest(GET_JOURNAL, fetchJournal);
}

export function* combinedJournalSaga() {
  yield all([watchFetchJournal()]);
}

export const SagaExports = {
  fetchJournal,
  watchFetchJournal,
};

export const adminSaga = {
  watchFetchJournal,
};
