const GET_USERS = "iomjelm/admin/GET_USERS";
const GET_USERS_FAILED = "iomjelm/admin/GET_USERS_FAILED";
const SET_USERS = "iomjelm/admin/SET_USERS";

const UPDATE_USERS = "iomjelm/admin/UPDATE_USERS";
const UPDATE_USERS_SUCCESS = "iomjelm/admin/UPDATE_USERS_SUCCESS";
const UPDATE_USERS_FAILED = "iomjelm/admin/UPDATE_USERS_FAILED";

const UPLOAD_LOGO = "iomjelm/admin/UPLOAD_LOGO";
const UPLOAD_LOGO_SUCCESS = "iomjelm/admin/UPLOAD_LOGO_SUCCESS";
const UPLOAD_LOGO_FAILED = "iomjelm/admin/UPLOAD_LOGO_FAILED";

const UPLOAD_FILE = "iomjelm/admin/UPLOAD_FILE";
const UPLOAD_FILE_SUCCESS = "iomjelm/admin/UPLOAD_FILE_SUCCESS";
const UPLOAD_FILE_FAILED = "iomjelm/admin/UPLOAD_FILE_FAILED";

const UPLOAD_CAROUSEL = "iomjelm/admin/UPLOAD_CAROUSEL";
const UPLOAD_CAROUSEL_SUCCESS = "iomjelm/admin/UPLOAD_CAROUSEL_SUCCESS";
const UPLOAD_CAROUSEL_FAILED = "iomjelm/admin/UPLOAD_CAROUSEL_FAILED";

const UPDATE_EDITORS = "iomjelm/admin/UPDATE_EDITORS";
const UPDATE_EDITORS_SUCCESS = "iomjelm/admin/UPDATE_EDITORS_SUCCESS";
const UPDATE_EDITORS_FAILED = "iomjelm/admin/UPDATE_EDITORS_FAILED";

const UPLOAD_JOURNAL = "iomjelm/admin/UPLOAD_JOURNAL";
const UPLOAD_JOURNAL_SUCCESS = "iomjelm/admin/UPLOAD_JOURNAL_SUCCESS";
const UPLOAD_JOURNAL_FAILED = "iomjelm/admin/UPLOAD_JOURNAL_FAILED";

const UPLOAD_IMAGES = "iomjelm/admin/UPLOAD_IMAGES";
const UPLOAD_IMAGES_SUCCESS = "iomjelm/admin/UPLOAD_IMAGES_SUCCESS";
const UPLOAD_IMAGES_FAILED = "iomjelm/admin/UPLOAD_IMAGES_FAILED";

const UPDATE_SCROLL_TEXT = "iomjelm/admin/UPDATE_SCROLL_TEXT";
const UPDATE_SCROLL_TEXT_SUCCESS = "iomjelm/admin/UPDATE_SCROLL_TEXT_SUCCESS";
const UPDATE_SCROLL_TEXT_FAILED = "iomjelm/admin/UPDATE_SCROLL_TEXT_FAILED";

const UPDATE_AIM_SCOPE = "iomjelm/admin/UPDATE_AIM_SCOPE";
const UPDATE_AIM_SCOPE_SUCCESS = "iomjelm/admin/UPDATE_AIM_SCOPE_SUCCESS";
const UPDATE_AIM_SCOPE_FAILED = "iomjelm/admin/UPDATE_AIM_SCOPE_FAILED";

export {
  GET_USERS,
  SET_USERS,
  GET_USERS_FAILED,
  UPDATE_USERS,
  UPDATE_USERS_SUCCESS,
  UPDATE_USERS_FAILED,
  UPLOAD_LOGO,
  UPLOAD_LOGO_SUCCESS,
  UPLOAD_LOGO_FAILED,
  UPLOAD_CAROUSEL,
  UPLOAD_CAROUSEL_SUCCESS,
  UPLOAD_CAROUSEL_FAILED,
  UPDATE_EDITORS,
  UPDATE_EDITORS_SUCCESS,
  UPDATE_EDITORS_FAILED,
  UPLOAD_FILE,
  UPLOAD_FILE_FAILED,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_JOURNAL,
  UPLOAD_JOURNAL_SUCCESS,
  UPLOAD_JOURNAL_FAILED,
  UPLOAD_IMAGES,
  UPLOAD_IMAGES_FAILED,
  UPLOAD_IMAGES_SUCCESS,
  UPDATE_SCROLL_TEXT,
  UPDATE_SCROLL_TEXT_SUCCESS,
  UPDATE_SCROLL_TEXT_FAILED,
  UPDATE_AIM_SCOPE,
  UPDATE_AIM_SCOPE_SUCCESS,
  UPDATE_AIM_SCOPE_FAILED,
};
