import { journalAction } from "../actions/journalAction";
const { getJournal } = journalAction;

const dispatchGetJournal = (dispatch) => () => {
  dispatch(getJournal());
};

const journalOperations = {
  dispatchGetJournal,
};

export default journalOperations;
