import React, { useEffect, useState } from "react";
import {
  Space,
  Table,
  Tag,
  Form,
  Modal,
  Button,
  Input,
  Upload,
  Popconfirm,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./JournalUploadComponent.css";
import ModalPDFViewerComponent from "../ModalPDFViewerComponent/ModalPDFViewerComponent";
import journalSelectors from "../../redux/selectors/journalSelectors";
import { UploadOutlined, SearchOutlined } from "@ant-design/icons";
import adminOperations from "../../redux/operations/adminOperations";
import adminSelectors from "../../redux/selectors/adminSelectors";

const JournalUploadComponent = () => {
  const [journalData, setJournalData] = useState([]);
  const [data, setData] = useState([]);
  const [editingRecord, setEditingRecord] = useState();
  const [pdf, setPdf] = useState();
  const [changedJournalValue, setChangedJournalValue] = useState(false);
  const [form] = Form.useForm();
  const [uploadFileName, setUploadFileName] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const [uploadServer, setUploadServer] = useState("");
  const { isFetchingJournal, journal, fileUpload, isUploadingJournal } =
    useSelector((state) => ({
      isFetchingJournal: journalSelectors.isFetchingJournal(state),
      journal: journalSelectors.journal(state),
      fileUpload: adminSelectors.fileUpload(state),
      isUploadingJournal: adminSelectors.isUploadingJournal(state),
    }));

  useEffect(() => {
    if (journal) {
      const tempJournal = journal;
      for (let i = 0; i < tempJournal.length; i++) {
        tempJournal[i].key = tempJournal[i]._id;
      }
      setData(journal);
      setJournalData(journal);
    }
  }, [journal]);

  const generateRandomLightColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  function showModalPdf(record) {
    setPdf(record.fileUrl);
    setIsModalOpen(true);
  }

  function showEditModal(record) {
    setEditingRecord(record);
    setPdf(record.fileUrl);
    setIsEditModalOpen(true);
    form.setFieldsValue(record);
  }
  function showAddEditModal() {
    setPdf("");
    form.resetFields();
    setIsEditModalOpen(true);
    setChangedJournalValue(true);
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filterIcon: <SearchOutlined />,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search name"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters()}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) =>
        record.name.toLowerCase().includes(value.toLowerCase()),
      render: (text, record) => (
        <a onClick={() => showModalPdf(record)}>
          <React.Fragment>{text}</React.Fragment>
        </a>
      ),
      ellipsis: true,
    },
    {
      title: "Author",
      dataIndex: "author",
      key: "author",
      ellipsis: true,
      render: (text, record) => {
        let color = "black";
        if (record.record_status === "delete") {
          color = "red";
        } else if (record.record_status === "update") {
          color = "blue";
        } else if (record.record_status === "append") {
          color = "green";
        } else {
          color = "black";
        }
        return <span style={{ color: color }}>{text}</span>;
      },
    },
    {
      title: "Tags",
      key: "tag",
      dataIndex: "tag",
      ellipsis: true,
      render: (tags) => (
        <>
          {tags.map((tag) => (
            <Tag color={generateRandomLightColor()} key={tag}>
              {tag}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => showEditModal(record)}>Edit</a>
          <Popconfirm
            title={
              record.record_status !== undefined &&
              record.record_status === "delete"
                ? "Are you sure to undo this deleted record?"
                : "Are you sure to delete this record?"
            }
            onConfirm={() => handleDeleteJournal(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <a type="link">
              {record.record_status !== undefined &&
              record.record_status === "delete"
                ? "Undo"
                : "Delete"}
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleDeleteJournal = (_id) => {
    const tempData = data;
    const deleteEditor = tempData.map((singleJournal) => {
      if (singleJournal._id == _id) {
        if ("record_status" in singleJournal) {
          delete singleJournal.record_status;
          return singleJournal;
        }
        return { ...singleJournal, record_status: "delete" };
      }
      return singleJournal;
    });
    setData(deleteEditor);
    setChangedJournalValue(true);
  };

  const handleUpdateJournal = (values) => {
    const tempData = data;
    let idExists = false;

    let updatedJournal = tempData.map((singleJournal) => {
      if (editingRecord !== undefined) {
        if (singleJournal._id == editingRecord._id) {
          idExists = true;
          let updatingJournal = {
            ...singleJournal,
            name: values.name,
            author: values.author,
            tag: values.tag.split(","),
            fileUrl: values.fileUrl,
            record_status: "update",
          };
          if (uploadFileName !== "") {
            updatingJournal["uploadFileName"] = uploadFileName;
          }
          return updatingJournal;
        }
      }
      return singleJournal;
    });

    if (!idExists) {
      let newEditor = {
        key: values.name,
        name: values.name,
        author: values.author,
        tag: values.tag.split(","),
        uploadFileName: uploadFileName,
        record_status: "append",
      };
      updatedJournal.push(newEditor);
    }

    setChangedJournalValue(true);
    setData(updatedJournal);
    setUploadFileName("");
  };

  useEffect(() => {
    let tempData = data;
    if (tempData.length !== 0) {
      if (fileUpload !== undefined) {
        for (let i = 0; i < tempData.length; i++) {
          let singleJournal = tempData[i];
          if (singleJournal.uploadFileName === fileUpload.name) {
            singleJournal.fileUrl = fileUpload.fileUrl;
          }
          tempData[i] = singleJournal;
        }
      }
      setUploadServer("uploaded");
      setData(tempData);
    }
  }, [data, fileUpload]);

  const onFinish = async (values) => {
    let record = {
      name: values.name,
      author: values.author,
      tag: values.tag,
    };
    if ("fileUrl" in values) {
      record["fileUrl"] = values.fileUrl;
    }
    if (uploadFileName !== "") {
      record["uploadFileName"] = uploadFileName;
    }
    if (Array.isArray(values.tag)) {
      record["tag"] = values.tag.join(", ");
    }
    handleUpdateJournal(record);
    setIsEditModalOpen(false);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setPdf("");
  };
  const handleCancel = () => {
    setIsEditModalOpen(false);
    setPdf("");
  };

  function generateRandomFileName() {
    const letters = "abcdefghijklmnopqrstuvwxyz";
    let result = "";

    for (let i = 0; i < 16; i++) {
      const randomIndex = Math.floor(Math.random() * letters.length);
      result += letters[randomIndex];
    }

    return result;
  }

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      let tempFileName = generateRandomFileName() + ".pdf";
      setUploadFileName(tempFileName);
      let formData = new FormData();
      formData.append("file", file);
      formData.append("subpath", "journal");
      formData.append("name", tempFileName);
      adminOperations.dispatchUploadFile(dispatch)(formData);
      setUploadServer("upload_to_server");
      onSuccess("ok");
    }, 0);
  };

  function undoJournalChanges() {
    setData(journalData);
  }
  useEffect(() => {
    if (uploadServer === "uploaded" || uploadServer === "") {
      adminOperations.dispatchUploadJournal(dispatch)(data);
    }
  }, [uploadServer, data, dispatch]);

  function applyJournalChanges() {
    let uploadServerStatus = "";
    for (let i = 0; i < data.length; i++) {
      if (!("fileUrl" in data[i])) {
        uploadServerStatus = "upload_to_server";
        setUploadServer(uploadServerStatus);
      }
    }
    if (uploadServerStatus === "") {
      adminOperations.dispatchUploadJournal(dispatch)(data);
    }
    setChangedJournalValue(false);
  }

  return (
    <div className="journal-upload-div">
      <Modal
        title="Basic Modal"
        open={isEditModalOpen}
        maskClosable={true}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          name="basic"
          form={form}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Journal Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input your journal name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Author"
            name="author"
            rules={[
              {
                required: true,
                message: "Please input your author!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Tag"
            name="tag"
            rules={[
              {
                required: true,
                message: "Please input your tag!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="File"
            name="fileUrl"
            rules={[
              {
                required: true,
                message: "Please input your pdf file!",
              },
            ]}
          >
            <Upload
              name="file"
              customRequest={dummyRequest}
              maxCount={1}
              accept=".pdf"
              multiple={false}
            >
              <Button icon={<UploadOutlined />}>Select pdf</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <div>
        <ModalPDFViewerComponent
          file={pdf}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      </div>
      <Table
        loading={isFetchingJournal}
        columns={columns}
        pagination={{ pageSize: 5 }}
        dataSource={data}
      />
      <div>
        {changedJournalValue && (
          <div>
            <Button type="primary" onClick={undoJournalChanges}>
              {" "}
              Undo{" "}
            </Button>
            {"   "}
            <Button
              type="primary"
              loading={isUploadingJournal}
              onClick={applyJournalChanges}
            >
              Apply Changes
            </Button>
          </div>
        )}
      </div>
      <br />
      <Button type="primary" onClick={showAddEditModal}>
        Add Journal
      </Button>
    </div>
  );
};

export default JournalUploadComponent;
