const getSession = () => {
  const token = sessionStorage.getItem("token");
  const email = sessionStorage.getItem("email");
  const persona = sessionStorage.getItem("persona");
  const name = sessionStorage.getItem("name");
  return { token: token, email: email, name: name, persona: persona };
};

const getSessionToken = () => {
  return sessionStorage.getItem("token");
};
const setSession = (token, name, email, persona) => {
  sessionStorage.setItem("token", token);
  sessionStorage.setItem("name", name);
  sessionStorage.setItem("email", email);
  sessionStorage.setItem("persona", persona);
};

const setSessionTimeOut = (status) => {
  sessionStorage.setItem("timeout", status);
};

const getSessionTimeOut = () => {
  return sessionStorage.getItem("timeout");
};

const clearSession = () => {
  sessionStorage.clear();
  window.location.reload();
};

const SessionHandler = {
  getSession,
  setSession,
  clearSession,
  getSessionToken,
  setSessionTimeOut,
  getSessionTimeOut,
};

export default SessionHandler;
