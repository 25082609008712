import { call, put, takeLatest, all } from "redux-saga/effects";
import axios from "../../utils/axiosHelper";

import {
  GET_USERS,
  SET_USERS,
  GET_USERS_FAILED,
  UPDATE_USERS,
  UPDATE_USERS_FAILED,
  UPDATE_USERS_SUCCESS,
  UPLOAD_LOGO,
  UPLOAD_LOGO_SUCCESS,
  UPLOAD_LOGO_FAILED,
  UPLOAD_CAROUSEL,
  UPLOAD_CAROUSEL_SUCCESS,
  UPLOAD_CAROUSEL_FAILED,
  UPDATE_EDITORS,
  UPDATE_EDITORS_FAILED,
  UPDATE_EDITORS_SUCCESS,
  UPLOAD_FILE,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILED,
  UPLOAD_JOURNAL,
  UPLOAD_JOURNAL_SUCCESS,
  UPLOAD_JOURNAL_FAILED,
  UPLOAD_IMAGES,
  UPLOAD_IMAGES_FAILED,
  UPLOAD_IMAGES_SUCCESS,
  UPDATE_SCROLL_TEXT,
  UPDATE_SCROLL_TEXT_FAILED,
  UPDATE_SCROLL_TEXT_SUCCESS,
  UPDATE_AIM_SCOPE,
  UPDATE_AIM_SCOPE_SUCCESS,
  UPDATE_AIM_SCOPE_FAILED,
} from "../types/adminTypes";

function* updateAimScope(action) {
  try {
    const response = yield call(axios.post, "/adhoc/aim_scope", action.payload);
    yield put({ type: UPDATE_AIM_SCOPE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: UPDATE_AIM_SCOPE_FAILED, payload: error.message });
  }
}

function* updateScrollText(action) {
  try {
    const response = yield call(
      axios.post,
      "/adhoc/scroll-text",
      action.payload
    );
    yield put({ type: UPDATE_SCROLL_TEXT_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: UPDATE_SCROLL_TEXT_FAILED, payload: error.message });
  }
}

function* uploadImages(action) {
  try {
    const response = yield call(axios.post, "/image-upload", action.payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    yield put({ type: UPLOAD_IMAGES_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: UPLOAD_IMAGES_FAILED, payload: error.message });
  }
}

function* uploadJournal(action) {
  try {
    const response = yield call(axios.post, "/journal", action.payload);
    yield put({ type: UPLOAD_JOURNAL_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: UPLOAD_JOURNAL_FAILED, payload: error.message });
  }
}

function* uploadFile(action) {
  try {
    const response = yield call(axios.post, "/file-upload", action.payload);
    yield put({ type: UPLOAD_FILE_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: UPLOAD_FILE_FAILED, payload: error.message });
  }
}

function* fetchUsers(action) {
  try {
    const response = yield call(axios.get, "/users", action.payload);
    yield put({ type: SET_USERS, payload: response.data });
  } catch (error) {
    yield put({ type: GET_USERS_FAILED, payload: error.message });
  }
}

function* updateUsers(action) {
  try {
    const response = yield call(axios.patch, "/users", action.payload);
    yield put({ type: UPDATE_USERS_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: UPDATE_USERS_FAILED, payload: error.message });
  }
}

function* uploadLogo(action) {
  try {
    const response = yield call(axios.post, "/adhoc/logo", action.payload);
    yield put({ type: UPLOAD_LOGO_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: UPLOAD_LOGO_FAILED, payload: error.message });
  }
}

function* updateCarousel(action) {
  try {
    const response = yield call(axios.post, "/adhoc/images", action.payload);
    yield put({ type: UPLOAD_CAROUSEL_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: UPLOAD_CAROUSEL_FAILED, payload: error.message });
  }
}

function* updateEditors(action) {
  try {
    const response = yield call(axios.patch, "/editor", action.payload);
    yield put({ type: UPDATE_EDITORS_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: UPDATE_EDITORS_FAILED, payload: error.message });
  }
}

function* watchUpdateAimScope() {
  yield takeLatest(UPDATE_AIM_SCOPE, updateAimScope);
}

function* watchUpdateScrollText() {
  yield takeLatest(UPDATE_SCROLL_TEXT, updateScrollText);
}

function* watchUploadImages() {
  yield takeLatest(UPLOAD_IMAGES, uploadImages);
}

function* watchUploadJournal() {
  yield takeLatest(UPLOAD_JOURNAL, uploadJournal);
}

function* watchUploadFile() {
  yield takeLatest(UPLOAD_FILE, uploadFile);
}

function* watchUpdateEditors() {
  yield takeLatest(UPDATE_EDITORS, updateEditors);
}

function* watchUpdateCarousel() {
  yield takeLatest(UPLOAD_CAROUSEL, updateCarousel);
}

function* watchUploadLogo() {
  yield takeLatest(UPLOAD_LOGO, uploadLogo);
}

function* watchUpdateUsers() {
  yield takeLatest(UPDATE_USERS, updateUsers);
}

function* watchFetchUsers() {
  yield takeLatest(GET_USERS, fetchUsers);
}

export function* combinedAdminSaga() {
  yield all([
    watchFetchUsers(),
    watchUpdateUsers(),
    watchUploadLogo(),
    watchUpdateCarousel(),
    watchUpdateEditors(),
    watchUploadFile(),
    watchUploadJournal(),
    watchUploadImages(),
    watchUpdateScrollText(),
    watchUpdateAimScope(),
  ]);
}
export const SagaExports = {
  fetchUsers,
  watchFetchUsers,
  updateUsers,
  watchUpdateUsers,
  uploadLogo,
  watchUploadLogo,
  updateCarousel,
  watchUpdateCarousel,
  updateEditors,
  watchUpdateEditors,
  uploadFile,
  watchUploadFile,
  uploadJournal,
  watchUploadJournal,
  uploadImages,
  watchUploadImages,
  updateScrollText,
  watchUpdateScrollText,
  updateAimScope,
  watchUpdateAimScope,
};

export const adminSaga = {
  watchFetchUsers,
  watchUpdateUsers,
  watchUploadLogo,
  watchUpdateCarousel,
  watchUpdateEditors,
  watchUploadFile,
  watchUploadJournal,
  watchUploadImages,
  watchUpdateScrollText,
  watchUpdateAimScope,
};
