import "./App.css";
import LayoutContainer from "../LayoutContainer/LayoutContainer";

function App() {
  return (
    <div className="App">
      <LayoutContainer />
    </div>
  );
}

export default App;
