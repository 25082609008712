import axios from "axios";
import SessionHandler from "./SessionHandler";
const { getSessionToken } = SessionHandler;

const apiUrl = `${window.location.origin}/api`;

if (process.env.NODE_ENV === "production") {
  axios.defaults.baseURL = apiUrl || "http://localhost:80/api";
} else {
  axios.defaults.baseURL = "http://localhost:" + process.env.API_PORT + "/api";
}

axios.defaults.headers.common["Authorization"] = `${getSessionToken()}`;

export default axios;
