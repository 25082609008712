import {
  ADMIN_LOGIN,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAILED,
} from "../types/authTypes";
import SessionHandler from "../../utils/SessionHandler";
const { setSession } = SessionHandler;

const authReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_LOGIN: {
      return {
        ...state,
        isFetchingLoginStatus: true,
      };
    }
    case ADMIN_LOGIN_SUCCESS: {
      setSession(
        action.payload.token,
        action.payload.name,
        action.payload.email,
        action.payload.persona
      );
      return {
        ...state,
        LoginStatus: action.payload.LoginStatus,
        name: action.payload.name,
        email: action.payload.email,
        persona: action.payload.persona,
        isFetchingLoginStatus: false,
      };
    }
    case ADMIN_LOGIN_FAILED: {
      return {
        ...state,
        LoginStatus: action.payload.LoginStatus,
        isFetchingLoginStatus: false,
      };
    }
    default:
      return state;
  }
};

export default authReducer;
