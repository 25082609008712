import { createSelector } from "reselect";

const selectAuth = (state) => state.auth;
export const selectIsFetchingLoginStatus = createSelector(
  [selectAuth],
  (auth) => auth.isFetchingLoginStatus
);

export const selectLoginStatus = createSelector(
  [selectAuth],
  (auth) => auth.LoginStatus
);

export const selectLoginName = createSelector(
  [selectAuth],
  (auth) => auth.name
);
export const selectLoginEmail = createSelector(
  [selectAuth],
  (auth) => auth.email
);
export const selectLoginPersona = createSelector(
  [selectAuth],
  (auth) => auth.persona
);

const authSelectors = {
  authIsFetchLoginStatus: selectIsFetchingLoginStatus,
  authLoginStatus: selectLoginStatus,
  authLoginName: selectLoginName,
  authLoginEmail: selectLoginEmail,
  authLoginPersona: selectLoginPersona,
};

export default authSelectors;
