/* eslint-disable react/prop-types */
import React from "react";
import { Modal } from "antd";

const ModalPDFViewerComponent = ({
  file,
  isModalOpen,
  setIsModalOpen,
  name,
}) => {
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      <Modal
        centered
        title={name}
        footer={null}
        open={isModalOpen}
        onCancel={handleCancel}
        width={1000}
      >
        <iframe
          src={file}
          width="100%"
          height="500px"
          title="PDF Viewer"
        ></iframe>
      </Modal>
    </div>
  );
};
export default ModalPDFViewerComponent;
