import { createSelector } from "reselect";

const selectHome = (state) => state["home"];

export const selectIsGettingHomeData = createSelector(
  [selectHome],
  (home) => home.isGettingHomeData
);

export const selecthomeData = createSelector(
  [selectHome],
  (home) => home.homeData
);

export const selectEditors = createSelector(
  [selectHome],
  (home) => home.editors
);

const homeSelectors = {
  isGettingHomeData: selectIsGettingHomeData,
  homeData: selecthomeData,
  editors: selectEditors,
};

export default homeSelectors;
