import React, { useState, useEffect } from "react";
import { Button, Form, Input, Card, Popconfirm, message } from "antd";
import "./RaiseQueryComponent.css";
import { useDispatch, useSelector } from "react-redux";
import helpOperations from "../../redux/operations/helpOperations";
import helpSelectors from "../../redux/selectors/helpSelectors";

const RaiseQueryComponent = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const { isSendingQueryStatus, querySubmitted } = useSelector((state) => ({
    isSendingQueryStatus: helpSelectors.isSendingQueryStatus(state),
    querySubmitted: helpSelectors.querySubmitted(state),
  }));

  const showPopconfirm = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setConfirmLoading(true);
    const formData = form.getFieldsValue();
    const queryData = {
      Name: formData.name,
      EmailID: formData.emailId,
      Subject: formData.subject,
      Message: formData.message,
    };
    helpOperations.dispatchSendQuery(dispatch)(queryData);
  };
  const handleCancel = () => {
    setOpen(false);
    message.info("User Cancelled");
  };

  useEffect(() => {
    if ((isSendingQueryStatus !== undefined) & (querySubmitted !== undefined)) {
      if (!isSendingQueryStatus & (querySubmitted | !querySubmitted)) {
        setOpen(false);
        setConfirmLoading(false);
      }

      if (!isSendingQueryStatus) {
        if (querySubmitted) {
          message.success("Submitted your query!");
          form.resetFields();
        } else {
          message.error("Something went Wrong!");
        }
      }
    }
  }, [form, isSendingQueryStatus, querySubmitted]);

  return (
    <div className="raise_query_div">
      <Card className="card">
        <div className="form_title">Tell us about the issue</div>
        <br />
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            requiredMarkValue: "Optional",
          }}
          requiredMark="Optional"
        >
          <Form.Item
            label="Name"
            required
            tooltip="Name Field is required"
            name="name"
          >
            <Input placeholder="Enter Your Name" />
          </Form.Item>
          <Form.Item
            label="Email Id"
            name="emailId"
            required
            tooltip="Email ID Field is required"
          >
            <Input type="email" placeholder="Enter Your @Email ID" />
          </Form.Item>
          <Form.Item
            label="Subject"
            name="subject"
            tooltip="Subject of the issue"
          >
            <Input placeholder="Enter the Subject" />
          </Form.Item>
          <Form.Item label="Message" name="message" tooltip="Your Message">
            <Input.TextArea placeholder="Enter the Message" />
          </Form.Item>
          <Form.Item>
            <Popconfirm
              title="Confirmation on your query"
              description="Are you sure to submit?"
              open={open}
              onConfirm={handleOk}
              okButtonProps={{
                loading: confirmLoading,
              }}
              onCancel={handleCancel}
            >
              <Button type="primary" shape="round" onClick={showPopconfirm}>
                Submit
              </Button>
            </Popconfirm>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default RaiseQueryComponent;
