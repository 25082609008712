/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload, Button, message } from "antd";
import "./CarouselEditComponent.css";
import homeSelectors from "../../redux/selectors/homeSelectors";
import { useDispatch, useSelector } from "react-redux";
import adminOperations from "../../redux/operations/adminOperations";
import adminSelectors from "../../redux/selectors/adminSelectors";
import homeOperations from "../../redux/operations/homeOperations";

const CarouselEditComponent = () => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [uploadStatus, setUploadStatus] = useState("");
  const [sendData, setSendData] = useState([]);
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState([]);
  const [carouselImages, setCarouselImages] = useState([]);
  const {
    uploadCarousel,
    isUploadingCarousel,
    imagesUpload,
    isUploadingImages,
  } = useSelector((state) => ({
    uploadCarousel: adminSelectors.uploadCarousel(state),
    isUploadingCarousel: adminSelectors.isUploadingCarousel(state),
    imagesUpload: adminSelectors.imagesUpload(state),
    isUploadingImages: adminSelectors.isUploadingImages(state),
  }));
  const { homeData } = useSelector((state) => ({
    homeData: homeSelectors.homeData(state),
  }));
  useEffect(() => {
    if (homeData !== undefined) {
      setCarouselImages(homeData.images);
    }
  }, [homeData]);

  useEffect(() => {
    console.log(uploadStatus);
  }, [uploadStatus]);

  useEffect(() => {
    if (uploadCarousel === "error") {
      message.error("Unable to upload Carousel");
    } else if (uploadCarousel === "success") {
      message.success("Carousel Uploaded");
    }
  }, [uploadCarousel]);
  useEffect(() => {
    const filesFromUrls = carouselImages.map((url, index) => ({
      uid: `-${index + 1}`,
      name: url.substring(url.lastIndexOf("/") + 1),
      status: "done",
      url: url,
    }));
    setFileList(filesFromUrls);
  }, [carouselImages]);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = ({ fileList }) => {
    setUploadStatus("upload_to_web");
    setFileList(fileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  useEffect(() => {
    if (!isUploadingImages) {
      if (imagesUpload !== undefined) {
        let listOfImageUrls = sendData;
        if (Array.isArray(imagesUpload)) {
          // adminOperations.dispatchUploadCarousel(dispatch)([
          //   ...imagesUpload,
          //   ...sendData,
          // ]);
          for (let i = 0; i < imagesUpload.length; i++) {
            listOfImageUrls.push(imagesUpload[i].imageUrl);
          }
        } else {
          listOfImageUrls.push(imagesUpload.imageUrl);
        }
        console.log(listOfImageUrls);
        setUploadStatus("upload_done");
        setSendData(listOfImageUrls);
      }
    }
  }, [imagesUpload, isUploadingImages, sendData]);
  const handleUploadChanges = async () => {
    let requestImages = [];
    let urlImages = [];
    for (let i = 0; i < fileList.length; i++) {
      if ("thumbUrl" in fileList[i]) {
        requestImages.push(fileList[i]);
      } else if ("url" in fileList[i]) {
        urlImages.push(fileList[i].url);
      }
    }
    setSendData(urlImages);
    const formData = new FormData();
    requestImages.forEach((file) => {
      formData.append("files", file.originFileObj);
    });
    formData.append("subpath", "Carousel");
    if (requestImages.length >= 1) {
      await adminOperations.dispatchUploadImages(dispatch)(formData);
      setUploadStatus("upload_to_storage");
    } else {
      setUploadStatus("upload_image_done");
    }
  };

  const handleChangesApply = async () => {
    await adminOperations.dispatchUploadCarousel(dispatch)(sendData);
    homeOperations.dispatchHomeData(dispatch)();
    setUploadStatus("");
  };

  return (
    <div className="carousel_edit">
      {fileList.length < 8 && (
        <Upload
          listType="picture-card"
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleChange}
          customRequest={dummyRequest}
        >
          {uploadButton}
        </Upload>
      )}
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
      {uploadStatus !== "" && (
        <div>
          {uploadStatus === "upload_to_web" && (
            <Button
              type="primary"
              loading={isUploadingImages}
              onClick={handleUploadChanges}
            >
              Save
            </Button>
          )}
          {(uploadStatus === "upload_done" ||
            uploadStatus === "upload_image_done") && (
            <Button
              type="primary"
              className="carousel_apply_changes"
              loading={isUploadingCarousel}
              onClick={handleChangesApply}
            >
              Apply Changes
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default CarouselEditComponent;
