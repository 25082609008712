import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./HomeContainer.css";
import { Spin } from "antd";
import CarouselComponent from "../../component/CarouselComponent/CarouselComponent";
import JournalListCardComponent from "../../component/JournalListCardComponent/JournalListCardComponent";
import AnnouncementComponent from "../../component/AnnouncementComponent/AnnouncementComponent";
import homeSelectors from "../../redux/selectors/homeSelectors";

const HomeContainer = () => {
  const [loading, setLoading] = useState(true);
  const [scrollText, setScrollText] = useState("");
  const { isGettingHomeData, homeData } = useSelector((state) => ({
    isGettingHomeData: homeSelectors.isGettingHomeData(state),
    homeData: homeSelectors.homeData(state),
  }));
  useEffect(() => {
    if (isGettingHomeData !== undefined) {
      setLoading(isGettingHomeData);
    }
  }, [isGettingHomeData]);
  useEffect(() => {
    if (homeData !== undefined) {
      if ("scroll_text" in homeData) {
        setScrollText(homeData.scroll_text);
      }
    }
  }, [homeData, setScrollText]);
  return (
    <div className="home_container">
      <Spin spinning={loading} fullscreen />
      {scrollText !== "" && (
        <div>
          <marquee className="marquee_text">{scrollText}</marquee>
        </div>
      )}
      <div>
        <CarouselComponent />
      </div>
      <div>
        <div className="home_card_component">
          <JournalListCardComponent />
          <AnnouncementComponent />
        </div>
      </div>
    </div>
  );
};

export default HomeContainer;
