import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "antd";
import "./JournalListCardComponent.css";
import homeSelectors from "../../redux/selectors/homeSelectors";
import { useSelector } from "react-redux";

const JournalListCardComponent = () => {
  const [visitors, setVisitors] = useState(0);
  const [journalCount, setJournalCount] = useState(0);
  const { homeData } = useSelector((state) => ({
    homeData: homeSelectors.homeData(state),
  }));
  useEffect(() => {
    if (homeData !== undefined) {
      setVisitors(homeData.visitors_count);
      setJournalCount(homeData.journal_count);
    }
  }, [homeData]);
  return (
    <div className="journal_list_card">
      <Row gutter={16}>
        <Col span={12}>
          <Card className="journal_card" title="Page Visitors" size="small">
            <p>{visitors}</p>
          </Card>
        </Col>
        <Col span={12}>
          <Card className="journal_card" title="No of Papers" size="small">
            <p>{journalCount}</p>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default JournalListCardComponent;
