/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./AdminLoginDrawerComponent.css";
import { Drawer, Button, Form, Input, Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import authOperations from "../../redux/operations/authOperations";
import authSelectors from "../../redux/selectors/authSelectors";

const AdminLoginDrawerComponent = (drawerStatus) => {
  const { open, setOpen, setAdminLoginData } = drawerStatus;
  const [loginData, setLoginData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  const { authIsFetchLoginStatus, authLoginStatus } = useSelector((state) => ({
    authIsFetchLoginStatus: authSelectors.authIsFetchLoginStatus(state),
    authLoginStatus: authSelectors.authLoginStatus(state),
  }));
  useEffect(() => {
    if ((authIsFetchLoginStatus === false) & (authLoginStatus === "Success")) {
      window.location.reload();
      setAdminLoginData(loginData);
    }
  }, [authLoginStatus, authIsFetchLoginStatus, loginData, setAdminLoginData]);

  useEffect(() => {
    if (authLoginStatus !== "" && authLoginStatus !== undefined) {
      setErrorMessage(
        authLoginStatus !== "Success" ? "Login Success" : authLoginStatus
      );
    }
  }, [authLoginStatus]);

  const onClose = () => {
    setOpen(false);
  };
  const onFinish = (values) => {
    authOperations.dispatchAdminLogin(dispatch)(values);
    setLoginData(values);
    //onClose();
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div>
      <Drawer title="Admin Login" onClose={onClose} open={open}>
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Email ID"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please input your email id!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          {errorMessage !== "" && (
            <div>
              <Alert
                message={authLoginStatus}
                type={authLoginStatus === "Success" ? "success" : "error"}
              />
              <br />
            </div>
          )}

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              loading={authIsFetchLoginStatus}
            >
              Login
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

export default AdminLoginDrawerComponent;
