import { ADMIN_LOGIN } from "../types/authTypes";

const adminLogin = (credentials) => {
  return {
    type: ADMIN_LOGIN,
    payload: credentials,
  };
};

export const authAction = {
  adminLogin,
};
