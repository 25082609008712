import React from "react";
import { Layout, Image } from "antd";
import "./EditorsViewComponent.css";
const { Sider, Content } = Layout;

const EditorsViewComponent = (prop) => {
  const { name, role, about, photourl } = prop;
  return (
    <Layout className="aboutus_layout">
      <Sider width="25%" theme="light" className="aboutus_sider">
        <div>
          <Image
            className="aboutus_profile"
            src={photourl}
            height={100}
            width={100}
          />
          <p className="aboutus_name">{name}</p>
          <p className="aboutus_role">{role}</p>
        </div>
      </Sider>
      <Content className="aboutus_content" theme="light">
        <div className="aboutus_content_text">{about}</div>
      </Content>
    </Layout>
  );
};

export default EditorsViewComponent;
