import React from "react";
import "./SubmissionContainer.css";
import { Table } from "antd";

const SubmissionContainer = () => {
  const columns = [
    {
      title: " ",
      dataIndex: "title",
      key: "title",
      render: (text) => (
        <span
          style={{
            color: "black",
            fontWeight: "bold",
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Font",
      dataIndex: "font",
      key: "font",
    },
    {
      title: "Style",
      dataIndex: "style",
      key: "style",
    },
    {
      title: "Font Size",
      dataIndex: "fontSize",
      key: "fontSize",
    },
    {
      title: "Text",
      dataIndex: "text",
      key: "text",
    },
    {
      title: "Align",
      dataIndex: "align",
      key: "align",
    },
    {
      title: "Spacing",
      dataIndex: "spacing",
      key: "spacing",
    },
  ];
  const dataSource = [
    {
      title: "Topic or Title of Paper",
      font: "Calibri(Body)",
      style: "(Upper Case)",
      fontSize: "16",
      text: "Bold",
      align: "Centre",
      spacing: "",
    },
    {
      title: "Author’s Name",
      font: "Calibri(Body)",
      style: "",
      fontSize: "14",
      text: "Normal",
      align: "Centre",
      spacing: "",
    },
    {
      title: "Author’s Designation",
      font: "Calibri(Body)",
      style: "",
      fontSize: "10",
      text: "Italic",
      align: "Centre",
      spacing: "",
    },
    {
      title: "Affiliation",
      font: "Calibri(Body)",
      style: "",
      fontSize: "10",
      text: "Italic",
      align: "Centre",
      spacing: "",
    },
    {
      title: "Headings",
      font: "Calibri(Body)",
      style: "(Upper Case)",
      fontSize: "11",
      text: "Bold",
      align: "Justify",
      spacing: "",
    },
    {
      title: "Main content",
      font: "Calibri(Body)",
      style: "",
      fontSize: "10",
      text: "Bold",
      align: "Justify",
      spacing: "1.15",
    },
    {
      title: "Citation",
      font: "Calibri",
      style: "",
      fontSize: "10",
      text: "Normal",
      align: "Justify",
      spacing: "",
    },
    {
      title: "Paragraphs",
      font: "Calibri(Body)",
      style: "Intended Style",
      fontSize: "10",
      text: "Normal",
      align: "Intended",
      spacing: "1.15",
    },
    {
      title: "References",
      font: "Calibri(Body)",
      style: "MLA Style, 9th Edition",
      fontSize: "8",
      text: "",
      align: "",
      spacing: "",
    },
  ];
  return (
    <div className="submission_container">
      <h4 className="guidelines-heading">Submission Guidelines for Authors</h4>
      <p>
        Please read the Instructions for Authors before submitting your
        manuscript. Submit manuscripts as e-mail attachment to the Editorial
        Office at: <a href="mailto:admin@iomjelm.in">admin@iomjelm.in</a>, a
        manuscript number will be mailed to the corresponding author same day or
        within 72 hours. The International Online Monthly Journal of English
        Literary Magna - IOMJELM will only accept manuscripts submitted via
        e-mail attachments only.{" "}
      </p>
      <p>
        After submission, the review process will take two to four days where
        three or more than three experts will review the paper .If the research
        paper is accepted for publication then you will receive online
        acceptance letter.
      </p>
      <b className="guidelines-heading">
        <u>
          Authors are requested to strictly follow the below mentioned author’s
          guidelines.
        </u>
      </b>
      <div className="submission-guidelines-text">
        <p>
          1. The research paper or article must be plagiarism free and original.
        </p>
        <p>
          2. The research paper sent for publication should not have been
          published elsewhere, or be under review for any other journal.
        </p>
        <p>
          3. The text of a submission should only be in ‘Calibri(Body) font, of
          10 point font size and 1.15 lines spaced paragraphs.
        </p>
        <p>
          4. The research paper should ideally range between 2000 – 5000 words
          included an abstract not exceeding 150-200 words and references.
        </p>
        <p>
          5. All figures, images, tables of the manuscript should be embedded
          into the original text. Separate files will not be accepted. For
          publishing purposes, the journal will request original media wherever
          required.
        </p>
        <p>
          6. MLA 9th Edition is preferred for in-text citing and References.
        </p>
        <p>
          7. For web references, as a minimum, the full URL should be given
          along with the date when the reference was last accessed.
        </p>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        bordered={true}
      />
    </div>
  );
};

export default SubmissionContainer;
