import { adminAction } from "../actions/adminAction";
const {
  getUsers,
  updateUsers,
  uploadLogo,
  uploadCarousel,
  updateEditors,
  uploadFile,
  uploadJournal,
  uploadImages,
  updateScrollText,
  updateAimScope,
} = adminAction;

const dispatchUpdateAimScope = (dispatch) => (aimScope) => {
  dispatch(updateAimScope(aimScope));
};

const dispatchUpdateScrollText = (dispatch) => (text) => {
  dispatch(updateScrollText(text));
};

const dispatchGetUsers = (dispatch) => () => {
  dispatch(getUsers());
};

const dispatchUpdateUsers = (dispatch) => (userList) => {
  dispatch(updateUsers(userList));
};

const dispatchUploadJournal = (dispatch) => (journalData) => {
  dispatch(uploadJournal(journalData));
};

const dispatchUploadFile = (dispatch) => (file) => {
  dispatch(uploadFile(file));
};

const dispatchUploadImages = (dispatch) => (images) => {
  dispatch(uploadImages(images));
};

const dispatchUploadLogo = (dispatch) => (logo) => {
  dispatch(uploadLogo(logo));
};

const dispatchUploadCarousel = (dispatch) => (carousel) => {
  dispatch(uploadCarousel(carousel));
};

const dispatchUpdateEditors = (dispatch) => (editorsData) => {
  dispatch(updateEditors(editorsData));
};

const usersOperations = {
  dispatchGetUsers,
  dispatchUpdateUsers,
  dispatchUploadLogo,
  dispatchUploadCarousel,
  dispatchUpdateEditors,
  dispatchUploadFile,
  dispatchUploadJournal,
  dispatchUploadImages,
  dispatchUpdateScrollText,
  dispatchUpdateAimScope,
};

export default usersOperations;
