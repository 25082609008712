import { SEND_QUERY, GET_QUERY } from "../types/helpTypes";

const sendQuery = (query) => {
  return {
    type: SEND_QUERY,
    payload: query,
  };
};

const getQuery = () => {
  return {
    type: GET_QUERY,
  };
};

export const helpAction = {
  sendQuery,
  getQuery,
};
