import React, { useEffect, useState } from "react";
import homeSelectors from "../../redux/selectors/homeSelectors";
import { useSelector } from "react-redux";
import { Result } from "antd";
import PropTypes from "prop-types";
import "./AimContainer.css";

const AimContainer = () => {
  const [aimScopeData, setAimScopeData] = useState([]);
  const { homeData } = useSelector((state) => ({
    homeData: homeSelectors.homeData(state),
  }));
  useEffect(() => {
    if (homeData !== undefined) {
      if ("aim_scope" in homeData) {
        setAimScopeData(homeData.aim_scope);
      }
    }
  }, [homeData]);
  const HeadingParagraph = ({ heading, description }) => {
    return (
      <div>
        <h2 className="heading_text">
          <u>{heading}</u> {"   "}-{" "}
        </h2>
        <p className="description_text">{description}</p>
      </div>
    );
  };
  HeadingParagraph.propTypes = {
    heading: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  };
  return (
    <div className="aim_container">
      {aimScopeData.length === 0 && (
        <Result
          status="404"
          title=""
          subTitle="Currently nothing to show here"
        />
      )}
      {aimScopeData.map((aimScope, index) => (
        <HeadingParagraph
          key={index}
          heading={aimScope.heading}
          description={aimScope.desciption}
        />
      ))}
    </div>
  );
};

export default AimContainer;
