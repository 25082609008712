import React, { useEffect, useState } from "react";
import { Upload, Button, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import "./LogoUploadComponent.css";
import adminOperations from "../../redux/operations/adminOperations";
import { useDispatch, useSelector } from "react-redux";
import adminSelectors from "../../redux/selectors/adminSelectors";

const LogoUploadComponent = () => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const [imagePreview, setImagePreview] = useState();
  const dispatch = useDispatch();
  const { isUploadingLogo, logoUpload } = useSelector((state) => ({
    isUploadingLogo: adminSelectors.isUploadingLogo(state),
    logoUpload: adminSelectors.logoUpload(state),
  }));
  useEffect(() => {
    if (logoUpload !== undefined) {
      if (logoUpload.status === "Uploaded") {
        messageApi.open({
          type: "success",
          content: "Logo Updated Successfully",
        });
      }
    }
  }, [logoUpload, messageApi]);
  const handleChange = ({ file }) => {
    setLoading(true);
    setImage(file);
    const reader = new FileReader();
    reader.onload = (event) => {
      setLoading(false);
      setImagePreview(event.target.result);
    };
    reader.readAsDataURL(file.originFileObj);
  };
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const uploadServer = () => {
    if (!loading) {
      let formData = new FormData();
      formData.append("file", image.originFileObj);
      adminOperations.dispatchUploadLogo(dispatch)(formData);
    }
  };
  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <div className="logo_upload_div">
      <h2 className="logo_upload_header">Upload the logo below</h2>
      {contextHolder}
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        multiple={false}
        customRequest={dummyRequest}
        onChange={handleChange}
        accept=".jpg,.jpeg,.png"
      >
        {image ? (
          <img
            src={imagePreview}
            alt="avatar"
            style={{
              width: "100%",
            }}
          />
        ) : (
          uploadButton
        )}
      </Upload>
      {image !== "" && image !== undefined && (
        <Button type="primary" loading={isUploadingLogo} onClick={uploadServer}>
          Apply Changes
        </Button>
      )}
    </div>
  );
};

export default LogoUploadComponent;
