import {
  GET_HOME_DATA,
  GET_HOME_DATA_FAILED,
  GET_HOME_DATA_SUCCESS,
  UPDATE_EDITORS,
  UPDATE_EDITORS_FAILED,
  UPDATE_EDITORS_SUCCESS,
  GET_EDITORS,
  GET_EDITORS_FAILED,
  SET_EDITORS,
} from "../types/homeTypes";
import { call, put, takeLatest, all } from "redux-saga/effects";
import axios from "../../utils/axiosHelper";

function* getHomeData(action) {
  try {
    const response = yield call(axios.get, "/adhoc", action.payload);
    yield put({ type: GET_HOME_DATA_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: GET_HOME_DATA_FAILED, payload: error.message });
  }
}

function* updateEditors(action) {
  try {
    const response = yield call(axios.post, "/editor", action.payload);
    yield put({ type: UPDATE_EDITORS_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: UPDATE_EDITORS_FAILED, payload: error.message });
  }
}

function* getEditors(action) {
  try {
    const response = yield call(axios.get, "/editor", action.payload);
    yield put({ type: SET_EDITORS, payload: response.data });
  } catch (error) {
    yield put({ type: GET_EDITORS_FAILED, payload: error.message });
  }
}

function* watchGetEditors() {
  yield takeLatest(GET_EDITORS, getEditors);
}

function* watchUpdateEditors() {
  yield takeLatest(UPDATE_EDITORS, updateEditors);
}

function* watchGetHomeData() {
  yield takeLatest(GET_HOME_DATA, getHomeData);
}

export function* combinedHomeSaga() {
  yield all([watchGetHomeData(), watchUpdateEditors(), watchGetEditors()]);
}
export const SagaExports = {
  getHomeData,
  watchGetHomeData,
  updateEditors,
  watchUpdateEditors,
  getEditors,
  watchGetEditors,
};

export const homeSaga = {
  watchGetHomeData,
  watchUpdateEditors,
  watchGetEditors,
};
