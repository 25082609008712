import { createSelector } from "reselect";

const selectAdmin = (state) => state.admin;
export const selectIsFetchingUsers = createSelector(
  [selectAdmin],
  (admin) => admin.isFetchingUsers
);

export const selectusers = createSelector(
  [selectAdmin],
  (admin) => admin.listOfUsers
);

export const selectIsUpdatingUsers = createSelector(
  [selectAdmin],
  (admin) => admin.isUpdatingUsers
);

export const selectIsUploadingLogo = createSelector(
  [selectAdmin],
  (admin) => admin.isUploadingLogo
);

export const selectLogoUpload = createSelector(
  [selectAdmin],
  (admin) => admin.logoUpload
);

export const selectIsUploadingCarousel = createSelector(
  [selectAdmin],
  (admin) => admin.isUploadingCarousel
);

export const selectUploadCarousel = createSelector(
  [selectAdmin],
  (admin) => admin.uploadCarousel
);

export const selectIsUpdatingEditors = createSelector(
  [selectAdmin],
  (admin) => admin.isUpdatingEditors
);

export const selectFileUpload = createSelector(
  [selectAdmin],
  (admin) => admin.fileUpload
);

export const selectIsUploadingJournal = createSelector(
  [selectAdmin],
  (admin) => admin.isUploadingJournal
);

export const selectJournalUpload = createSelector(
  [selectAdmin],
  (admin) => admin.journalUpload
);

export const selectIsUploadingImages = createSelector(
  [selectAdmin],
  (admin) => admin.isUploadingImages
);

export const selectImagesUpload = createSelector(
  [selectAdmin],
  (admin) => admin.imagesUpload
);

export const selectIsUpdatingScrollText = createSelector(
  [selectAdmin],
  (admin) => admin.isUpdatingScrollText
);

export const selectUpdatedScrollText = createSelector(
  [selectAdmin],
  (admin) => admin.scroll_text_update_status
);

export const selectIsUpdatingAimScope = createSelector(
  [selectAdmin],
  (admin) => admin.isUpdatingAimScope
);

export const selectAimScopeStatus = createSelector(
  [selectAdmin],
  (admin) => admin.aimScopeStatus
);

const adminSelectors = {
  isFetchingUsers: selectIsFetchingUsers,
  users: selectusers,
  logoUpload: selectLogoUpload,
  isUpdatingUsers: selectIsUpdatingUsers,
  isUploadingLogo: selectIsUploadingLogo,
  uploadCarousel: selectUploadCarousel,
  isUploadingCarousel: selectIsUploadingCarousel,
  isUpdatingEditors: selectIsUpdatingEditors,
  fileUpload: selectFileUpload,
  isUploadingJournal: selectIsUploadingJournal,
  journalUpload: selectJournalUpload,
  imagesUpload: selectImagesUpload,
  isUploadingImages: selectIsUploadingImages,
  isUpdatingScrollText: selectIsUpdatingScrollText,
  scroll_text_update_status: selectUpdatedScrollText,
  isUpdatingAimScope: selectIsUpdatingAimScope,
  aimScopeStatus: selectAimScopeStatus,
};

export default adminSelectors;
