import { GET_JOURNAL } from "../types/journalTypes";

const getJournal = () => {
  return {
    type: GET_JOURNAL,
  };
};

export const journalAction = {
  getJournal,
};
