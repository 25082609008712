import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Table,
  Space,
  Popconfirm,
  Modal,
  Button,
  Input,
  Form,
  Upload,
} from "antd";
import homeOperations from "../../redux/operations/homeOperations";
import homeSelectors from "../../redux/selectors/homeSelectors";
import adminSelectors from "../../redux/selectors/adminSelectors";
import adminOperations from "../../redux/operations/adminOperations";
import SessionHandler from "../../utils/SessionHandler";

const EditorUserComponent = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const persona = SessionHandler.getSession().persona;
  const [uploadServer, setUploadServer] = useState("");
  const [uploadImageName, setUploadImageName] = useState("");
  const [editingRecord, setEditingRecord] = useState();
  const [editorsData, setEditorsData] = useState([]);
  const [ImageLoading, setImageLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState();
  const [editorPhotourl, setEditorPhotourl] = useState("");
  const [changedEditorValue, setChangedEditorValue] = useState(false);
  const [isEditEditorModalOpen, setIsEditEditorModalOpen] = useState(false);
  const { editors, isUpdatingEditors, imagesUpload } = useSelector((state) => ({
    editors: homeSelectors.editors(state),
    isUpdatingEditors: adminSelectors.isUpdatingEditors(state),
    imagesUpload: adminSelectors.imagesUpload(state),
  }));
  useEffect(() => {
    if (editors !== undefined && editors.length > 0) {
      setEditorsData(editors);
    }
  }, [editors]);

  useEffect(() => {
    let tempEditorsData = editorsData;
    if (imagesUpload !== undefined) {
      if (tempEditorsData !== undefined && tempEditorsData.length > 0) {
        for (let i = 0; i < tempEditorsData.length; i++) {
          if ("uploadFileName" in tempEditorsData[i]) {
            if (
              (tempEditorsData[i].uploadFileName === uploadImageName) &
              (uploadImageName === imagesUpload.name)
            ) {
              tempEditorsData[i].photourl = imagesUpload.imageUrl;
            }
          }
        }
      }
      setEditorsData(tempEditorsData);
    }
  }, [editorsData, imagesUpload, uploadImageName]);

  useEffect(() => {
    let uploadServerStatus = "";
    for (let i = 0; i < editorsData.length; i++) {
      if (!("photourl" in editorsData[i])) {
        uploadServerStatus = "upload_server";
        setUploadServer(uploadServerStatus);
      }
    }
  }, [editorsData]);

  useEffect(() => {
    if (uploadServer === "upload_server") {
      adminOperations.dispatchUpdateEditors(dispatch)(editorsData);
      homeOperations.dispatchGetEditors(dispatch)();
      console.log(uploadServer);
      setUploadServer("");
    }
  }, [uploadServer, dispatch, editorsData]);

  const columns = [
    {
      title: "Profile",
      dataIndex: "photourl",
      key: "photourl",
      render: (photourl) => (
        <img src={photourl} alt="User" style={{ width: 50, height: 50 }} />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        let color = "black";
        if (record.record_status === "delete") {
          color = "red";
        } else if (record.record_status === "update") {
          color = "blue";
        } else if (record.record_status === "append") {
          color = "green";
        } else {
          color = "black";
        }
        return <span style={{ color: color }}>{text}</span>;
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (text, record) => {
        let color = "black";
        if (record.record_status === "delete") {
          color = "red";
        } else if (record.record_status === "update") {
          color = "blue";
        } else if (record.record_status === "append") {
          color = "green";
        } else {
          color = "black";
        }
        return <span style={{ color: color }}>{text}</span>;
      },
    },
    {
      title: "About",
      dataIndex: "about",
      key: "about",
      render: (text, record) => {
        let color = "black";
        if (record.record_status === "delete") {
          color = "red";
        } else if (record.record_status === "update") {
          color = "blue";
        } else if (record.record_status === "append") {
          color = "green";
        } else {
          color = "black";
        }
        return <span style={{ color: color }}>{text}</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a
            disabled={persona !== "Admin"}
            onClick={() => handleEditEditorModal(record)}
          >
            Edit {record.name}
          </a>
          <Popconfirm
            disabled={persona !== "Admin"}
            title={
              record.record_status !== undefined &&
              record.record_status === "delete"
                ? "Are you sure to undo this deleted record?"
                : "Are you sure to delete this record?"
            }
            onConfirm={() => handleDelete(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <a type="link" disabled={persona !== "Admin"}>
              {record.record_status !== undefined &&
              record.record_status === "delete"
                ? "Undo"
                : "Delete"}
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleEditEditorModal = (record) => {
    if (persona === "Admin") {
      setEditingRecord(record);
      form.setFieldsValue(record);
      setEditorPhotourl(record.photourl);
      setIsEditEditorModalOpen(true);
    }
  };

  const handleDelete = (_id) => {
    const editorData = editorsData;
    const deleteEditor = editorData.map((singleEditor) => {
      if (singleEditor._id == _id) {
        if ("record_status" in singleEditor) {
          delete singleEditor.record_status;
          return singleEditor;
        }
        setChangedEditorValue(true);
        return { ...singleEditor, record_status: "delete" };
      }
      return singleEditor;
    });
    setEditorsData(deleteEditor);
  };

  const handleUpdate = (values) => {
    let tempEditorData = editorsData;
    let idExists = false;
    let updatedEditor = tempEditorData.map((singleEditor) => {
      if (editingRecord !== undefined) {
        if (singleEditor._id == editingRecord._id) {
          idExists = true;
          let updatingEditor = {
            ...singleEditor,
            about: values.about,
            name: values.name,
            role: values.role,
            uploadFileName: uploadImageName,
            photourl: editorPhotourl || values.photourl,
            record_status: "update",
          };
          if ((imagesUpload !== undefined) & (uploadImageName !== "")) {
            if (imagesUpload.name === uploadImageName) {
              updatingEditor.photourl = imagesUpload.fileUrl;
            }
          }
          return updatingEditor;
        }
      }
      return singleEditor;
    });
    if (!idExists) {
      let newEditor = {
        key: values.name,
        name: values.name,
        role: values.role,
        about: values.about,
        uploadFileName: uploadImageName,
        photourl: editorPhotourl || values.photourl,
        record_status: "append",
      };
      if ((imagesUpload !== undefined) & (uploadImageName !== "")) {
        if (imagesUpload.name === uploadImageName) {
          newEditor.photourl = imagesUpload.fileUrl;
        }
      }
      updatedEditor.push(newEditor);
    }
    setChangedEditorValue(true);
    setEditorsData(updatedEditor);
    setEditorPhotourl();
    setImagePreview();
  };

  const onFinish = (values) => {
    handleUpdate(values);
    setEditingRecord(values);
    setIsEditEditorModalOpen(false);
  };
  const handleEditEditorModalOk = () => {
    setIsEditEditorModalOpen(false);
  };
  const handleEditEditorModalCancel = () => {
    setIsEditEditorModalOpen(false);
  };

  const undoChanges = () => {
    setChangedEditorValue(false);
    setEditorsData(editors);
  };
  function generateRandomFileName() {
    const letters = "abcdefghijklmnopqrstuvwxyz";
    let result = "";

    for (let i = 0; i < 16; i++) {
      const randomIndex = Math.floor(Math.random() * letters.length);
      result += letters[randomIndex];
    }

    return result;
  }

  const dummyRequest = async ({ file, onSuccess }) => {
    let tempFileName = generateRandomFileName() + ".jpeg";
    setUploadImageName(tempFileName);
    const formData = new FormData();
    formData.append("files", file);
    formData.append("subpath", "editors");
    formData.append("name", tempFileName);
    await adminOperations.dispatchUploadImages(dispatch)(formData);

    onSuccess("ok");
  };

  const applyChanges = () => {
    let requestData = [];
    let serverUpload = "";
    for (let i = 0; i < editorsData.length; i++) {
      if ("record_status" in editorsData[i]) {
        delete editorsData[i].uploadFileName;
        requestData.push(editorsData[i]);
      }
      if (!("photourl" in editorsData[i])) {
        serverUpload = "wait_for_photoUrl";
        setUploadServer(serverUpload);
      }
    }
    setEditorsData(requestData);
    if (serverUpload === "") {
      adminOperations.dispatchUpdateEditors(dispatch)(requestData);
      homeOperations.dispatchGetEditors(dispatch)();
    }

    setChangedEditorValue(false);
  };

  const handleAddEditor = () => {
    form.resetFields();
    setEditorPhotourl();
    setIsEditEditorModalOpen(true);
  };

  const handleImageChange = ({ file }) => {
    setImageLoading(false);
    //setPhotourl(url);
    const reader = new FileReader();
    reader.onload = (event) => {
      setImageLoading(false);
      setImagePreview(event.target.result);
    };
    reader.readAsDataURL(file.originFileObj);
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {ImageLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  return (
    <div>
      <div>
        <Modal
          title="Edit Editor"
          open={isEditEditorModalOpen}
          onOk={handleEditEditorModalOk}
          onCancel={handleEditEditorModalCancel}
          footer={null}
        >
          <Form
            form={form}
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your name!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Role"
              name="role"
              rules={[
                {
                  required: true,
                  message: "Please input your role!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="About"
              name="about"
              rules={[
                {
                  required: true,
                  message: "Please input your about!",
                },
              ]}
            >
              <Input.TextArea placeholder="About Editor" />
            </Form.Item>

            <Form.Item
              label="Photo"
              name="photourl"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={dummyRequest}
                accept=".jpg,.jpeg,.png"
                onChange={handleImageChange}
              >
                {editorPhotourl || imagePreview ? (
                  <img
                    src={editorPhotourl || imagePreview}
                    alt="avatar"
                    style={{
                      width: "100%",
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
      <Table columns={columns} dataSource={editorsData} rowKey="_id" />
      <div>
        {changedEditorValue && (
          <div>
            <Button type="primary" onClick={undoChanges}>
              {" "}
              Undo{" "}
            </Button>
            {"    "}
            <Button
              type="primary"
              loading={isUpdatingEditors}
              onClick={applyChanges}
            >
              {" "}
              Apply Changes{" "}
            </Button>
          </div>
        )}
        <div className="add_user_button">
          <Button
            type="primary"
            disabled={persona !== "Admin"}
            onClick={handleAddEditor}
            style={{
              marginBottom: 16,
            }}
          >
            Add Editor
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditorUserComponent;
