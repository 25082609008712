const GET_HOME_DATA = "iomjelm/home/GET_HOME_DATA";
const GET_HOME_DATA_FAILED = "iomjelm/home/GET_HOME_DATA_FAILED";
const GET_HOME_DATA_SUCCESS = "iomjelm/home/GET_HOME_DATA_SUCCESS";

const UPDATE_EDITORS = "iomjelm/home/UPDATE_EDITORS";
const UPDATE_EDITORS_SUCCESS = "iomjelm/home/UPDATE_EDITORS_SUCCESS";
const UPDATE_EDITORS_FAILED = "iomjelm/home/UPDATE_EDITORS_FAILED";

const GET_EDITORS = "iomjelm/home/GET_EDITORS";
const GET_EDITORS_FAILED = "iomjelm/home/GET_EDITORS_FAILED";
const SET_EDITORS = "iomjelm/home/SET_EDITORS";

const GET_SCROLL_TEXT = "iomjelm/home/GET_SCROLL_TEXT";
const GET_SCROLL_TEXT_FAILED = "iomjelm/home/GET_SCROLL_TEXT_FAILED";
const SET_SCROLL_TEXT = "iomjelm/home/SET_SCROLL_TEXT";

export {
  GET_HOME_DATA,
  GET_HOME_DATA_FAILED,
  GET_HOME_DATA_SUCCESS,
  UPDATE_EDITORS,
  UPDATE_EDITORS_FAILED,
  UPDATE_EDITORS_SUCCESS,
  GET_EDITORS,
  GET_EDITORS_FAILED,
  SET_EDITORS,
  GET_SCROLL_TEXT,
  GET_SCROLL_TEXT_FAILED,
  SET_SCROLL_TEXT,
};
